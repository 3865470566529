import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

export const InvoicesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
      <path
        d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM6 7H12C12.552 7 13 7.448 13 8C13 8.552 12.552 9 12 9H6C5.448 9 5 8.552 5 8C5 7.448 5.448 7 6 7ZM6 11H12C12.552 11 13 11.448 13 12C13 12.552 12.552 13 12 13H6C5.448 13 5 12.552 5 12C5 11.448 5.448 11 6 11ZM18 17H14C13.448 17 13 16.552 13 16C13 15.448 13.448 15 14 15H18C18.552 15 19 15.448 19 16C19 16.552 18.552 17 18 17ZM18 13H16C15.448 13 15 12.552 15 12C15 11.448 15.448 11 16 11H18C18.552 11 19 11.448 19 12C19 12.552 18.552 13 18 13ZM18 9H16C15.448 9 15 8.552 15 8C15 7.448 15.448 7 16 7H18C18.552 7 19 7.448 19 8C19 8.552 18.552 9 18 9Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
