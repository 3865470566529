import { useState } from 'react'

export enum PaginationModes {
  INFINITE = 'INFINITE',
  NUMBERS = 'NUMBERS',
  DISABLED = 'DISABLED',
}

interface UsePaginationConfigurationProps {
  mode: PaginationModes
  perPage: number
  pageParam?: string
}

export const usePaginationConfiguration = ({ mode, perPage }: UsePaginationConfigurationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const config: [number, number] =
    mode === PaginationModes.NUMBERS ? [currentPage - 1, perPage] : [0, currentPage * perPage]

  return {
    mode,
    config,
    currentPage,
    setCurrentPage,
  }
}
