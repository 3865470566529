import NextLink from 'next/link'

import { shadows } from '@dentalux/ui-library-core/cjs/themes/shadows/shadows'

import { avatarClasses, Box, IconButton, MenuList, Typography } from '@mui/material'
import BaseListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'

export const Link = styled(NextLink)({
  textDecoration: 'unset',
})

export const PhotoIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 12,
  bottom: -3,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.common.white,
  borderRadius: '50%',
  boxShadow: shadows[3],
}))

export const Frame = styled(Box)({
  position: 'relative',
  cursor: 'pointer',
})

export const Trigger = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.common.white,
}))

export const About = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 2),
  padding: theme.spacing(2, 0, 3),
  borderBottom: `1px solid ${theme.palette.grey[300]}`,

  [`.${avatarClasses.root}`]: {
    marginRight: theme.spacing(2),
  },
}))

export const Name = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
}))

Name.defaultProps = {
  variant: 'Lato Emphasized 1',
}

export const ListItemText = styled(BaseListItemText)({})

ListItemText.defaultProps = {
  primaryTypographyProps: {
    variant: 'Lato Emphasized 2',
  },
}

export const BaseMenuList = styled(MenuList)({
  padding: 0,
})
