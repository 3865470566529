import React from 'react'

import Link from 'next/link'

import { DataTestIds } from 'src/config/dataTestIds'

import SettingsMenu from '../SettingsMenu'
import SideBarMenu from '../SideBarMenu'

import * as S from './AppBar.styles'

const AppBar = () => (
  <S.AppBar
    leftContent={<SideBarMenu />}
    middleContent={
      <Link href="/" passHref data-testid={DataTestIds.HeaderLogo}>
        <S.Logo />
      </Link>
    }
    rightContent={<SettingsMenu />}
  />
)

export default AppBar
