import React from 'react'

import ProgressCheckIcon from '../icons/ProgressCheck'

import * as S from './StepperProgress.styles'

const StepperProgress = ({ steps, current, finished }: { steps: number; current: number; finished?: boolean }) => {
  const renderStep = (_, index) => {
    const isCurrent = index === current
    const isCompleted = index < current || finished

    const progress = current + 1 === index ? (current / steps) * 100 : current >= index ? 100 : 0

    return (
      <S.StepperStep key={`stepper-progress-item-${index}`}>
        <S.LabelContainer>
          <S.StepperStepIndex filled={isCurrent || isCompleted}>
            {isCompleted ? <ProgressCheckIcon /> : index + 1}
          </S.StepperStepIndex>
        </S.LabelContainer>

        {!!index && <S.LinearProgress value={progress} />}
      </S.StepperStep>
    )
  }

  return <S.Root>{Array.from({ length: steps }).map(renderStep)}</S.Root>
}

export default StepperProgress
