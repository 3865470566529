import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginBottom: theme.spacing(1),
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 384,
  },
}))

Root.defaultProps = {
  variant: 'Lato Caption',
}
