import { Components, Theme } from '@mui/material'

const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      top: 0,
      display: 'flex',
      color: theme.palette.primary.main,
      transform: `translate(0px, ${theme.spacing(2)})`,
      ...theme.typography['Lato Body 1'],

      '&.Mui-error': {
        color: theme.palette.error[800],
      },

      '&.Mui-disabled': {
        opacity: 0.38,
        color: theme.palette.grey[800],
      },
    }),
    shrink: ({ theme }) => ({
      transform: `translate(0px, ${theme.spacing(1)})`,
      ...theme.typography['Lato Caption'],
    }),
    asterisk: ({ theme }) => ({
      color: theme.palette.error[800],
      order: '-1',
    }),
  },
}

export default MuiInputLabel
