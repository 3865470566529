import React, { ReactNode } from 'react'

import { DialogProps } from '@mui/material'

import * as S from './Dialog.styles'

export type Props = DialogProps & {
  title: string
  icon?: ReactNode
  actions: ReactNode
  onClickInside?: () => void
  dataTestId?: string
}

const Dialog = ({ open, title, icon, children, actions, onClickInside, dataTestId, ...rest }: Props) => (
  <S.Root
    data-testid={dataTestId}
    open={open}
    onClick={onClickInside}
    icon={!!icon}
    onClose={(e: React.MouseEvent) => {
      rest?.onClose(e, 'escapeKeyDown')
      e.stopPropagation()
    }}
    {...rest}
  >
    <S.DialogIcon>{icon}</S.DialogIcon>

    <S.DialogTitle>{title}</S.DialogTitle>

    <S.DialogContent>{children}</S.DialogContent>

    <S.DialogActions>{actions}</S.DialogActions>
  </S.Root>
)

export default Dialog
