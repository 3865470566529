import NextLink from 'next/link'

import { styled } from '@mui/material/styles'

import { APPBAR_HEIGHT } from 'src/components/AppBar/AppBar.styles'

import { CONTENT_WIDTH } from '../TabContentTemplate/TabContentTemplate.styles'

export const Wrapper = styled('div')({})

export const Content = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.spacing(APPBAR_HEIGHT)})`,
  padding: theme.spacing(3, 2),
  margin: 'auto',
  maxWidth: theme.spacing(CONTENT_WIDTH),
}))

export const Link = styled(NextLink)(() => ({
  display: 'flex',
  cursor: 'pointer',
}))
