import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { MenuIndicatorKind } from './MenuIndicator'

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

export const Indicator = styled(Box)<{ kind: MenuIndicatorKind }>(({ theme, kind }) => ({
  width: 20,
  height: 20,
  flexShrink: 0,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  background: kind === MenuIndicatorKind.Error ? colors.error[800] : colors.orange[300],
}))
