import React, { ReactNode } from 'react'

import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

const AnaTranslations = dynamic(() =>
  import('@dentalux/ui-library-core/cjs/custom/components/ana/components/ana-translations/ana-translations').then(
    (mod) => mod.AnaTranslations
  )
)

import { Language } from '@dentalux/ui-library-core/cjs/type/Translations'

import AnamnesisScreen, { AnamnesisScreenRenderProps } from 'src/screens/Anamnesis/Anamnesis/Anamnesis'
import { withAnamnesisReferenceIdModeStaticPaths } from 'src/staticPaths/withAnamnesisReferenceIdModeStaticPaths'
import withTranslation from 'src/staticProps/withTranslation'

const AnamnesisPage = (props: { EndBarContent?: ReactNode; children?: AnamnesisScreenRenderProps | ReactNode }) => {
  const { i18n } = useTranslation()

  return (
    <AnaTranslations
      language={i18n.language as Language}
      clientConfig={{
        space: process.env.NEXT_PUBLIC_ANA_TRANSLATIONS_SPACE,
        accessToken: process.env.NEXT_PUBLIC_ANA_TRANSLATIONS_ACCESS_TOKEN,
      }}
    >
      <AnamnesisScreen {...props} />
    </AnaTranslations>
  )
}

export const getStaticProps = withTranslation

export const getStaticPaths = withAnamnesisReferenceIdModeStaticPaths

export default AnamnesisPage
