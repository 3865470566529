import React, { ReactElement } from 'react'

import { AuthenticationHandler } from '@dentalux/security'

type RouterGuardProps = {
  path: string
  children: ReactElement
}

const unprotectedRoutes = {
  // legals
  '/terms': true,
  '/information': true,
  '/privacy': true,
  '/joint-controllership': true,
  // auth
  '/registration': true,
  '/login': true,
  '/verifyphone': true,
  '/verifyphone/[token]': true,
  '/verifyemail': true,
  '/verifyemail/[token]': true,
  '/registration/[token]': true,
  '/forgot-password': true,
  '/reset-password/[token]': true,
  '/reset-phone-number/[token]': true,
  // other
  '/contact': true,
  '/farewell': true,
}

const RouteGuard = ({ children, path }: RouterGuardProps) => {
  if (unprotectedRoutes[path]) return <>{children}</>

  return <AuthenticationHandler>{children}</AuthenticationHandler>
}

export default RouteGuard
