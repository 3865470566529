import React, { ReactNode } from 'react'

import { Notice } from '../Notice/Notice'

import * as S from './EndBar.styles'

export const EndBar = ({
  children,
  notice,
  ...rest
}: {
  children?: ReactNode | ReactNode[]
  notice?: ReactNode | ReactNode[]
}) => {
  return (
    <S.Root {...rest}>
      {notice && <Notice>{notice}</Notice>}
      {children}
    </S.Root>
  )
}
