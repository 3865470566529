import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Label = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}))

Label.defaultProps = {
  variant: 'Lato Caption Bold',
}
