import makeInitials from 'initials'
import { Person } from 'src/@types/Person'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import usePerson from 'src/graphql/hooks/usePerson'

export const useInitials = ({ person }: { person?: Person }) => {
  const personReferenceId = usePersonId()

  const { data } = usePerson({
    variables: { personReferenceId },
    skip: !personReferenceId || !!person,
  })

  const source = person ?? data

  return !source?.firstName || !source?.lastName ? '' : makeInitials([source?.firstName, source?.lastName].join(' '))
}
