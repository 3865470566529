import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Root = styled('section')({
  maxWidth: 552,
  margin: 'auto',
})

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: 'center',
  color: colors.grey[900],
}))

Title.defaultProps = {
  variant: 'Lato H4 Bold',
}
