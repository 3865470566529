import React, { ReactNode } from 'react'

import { useRouter } from 'next/router'

import Tab from '@mui/material/Tab'

import * as S from './TabContentTemplate.styles'

export type TabConfig = {
  label: ReactNode
  icon?: React.VFC
  effect?: () => void
  path: string
}

type TabContentTemplateProps = {
  tabs: TabConfig[]
  basePath: string
  children?: ReactNode | ReactNode[]
}

const getTabPath = (basePath: string, tabPath: string) => {
  const concat = basePath + tabPath

  if (concat.length === 1) return concat

  // Remove the trailing `/`
  return concat.replace(/\/$/g, '')
}

const TabContentTemplate = ({ tabs, basePath, children }: TabContentTemplateProps) => {
  const router = useRouter()

  const value = tabs.findIndex((tab) => {
    const [currentPath] = router.asPath.split('?')

    return currentPath === getTabPath(basePath, tab.path)
  })

  const handleChange = (_: unknown, newValue: number) => {
    const tab = tabs[newValue]

    router.replace(getTabPath(basePath, tab.path), undefined, { shallow: true })

    if (!tab) return console.error(`Tab at index ${newValue} not found.`)

    if (tab.effect) {
      tab.effect()
    }
  }

  return (
    <>
      <S.TabsContainer>
        <S.Tabs
          value={value}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleChange}
          TabIndicatorProps={{ children: <span /> }}
          aria-label="Tabs"
        >
          {tabs.map((tab) => {
            const Icon = tab.icon

            return (
              <Tab
                icon={Icon ? <Icon /> : undefined}
                iconPosition="start"
                disableFocusRipple
                color="secondary"
                key={tab.path}
                label={tab.label}
              />
            )
          })}
        </S.Tabs>
      </S.TabsContainer>

      <S.Content>{children}</S.Content>
    </>
  )
}

export default TabContentTemplate
