import { useQuery } from 'react-query'

import api from 'api'

const useClinic = ({ clinicReferenceId, enabled = true }: { clinicReferenceId: string; enabled?: boolean }) => {
  const query = useQuery(['clinic', clinicReferenceId], () => api.clinic.getClinic(clinicReferenceId), {
    select: ({ phones, ...rest }) => ({ ...rest, phoneNumber: phones.legacyNumber }),
    enabled: !!clinicReferenceId && enabled,
    refetchOnWindowFocus: false,
    cacheTime: 5 * 60 * 1000,
  })

  return query
}

export default useClinic
