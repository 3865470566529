import React from 'react'

import dynamic from 'next/dynamic'

const LoaderComponent = dynamic(() => import('@dentalux/ui-library-core/cjs').then((mod) => mod.Loader))

const Loader = (props) => {
  return <LoaderComponent {...props} />
}

export default Loader
