import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Typography, styled } from '@mui/material'

export const Root = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 'auto', 1.5),
  color: colors.grey[900],
  maxWidth: 650,
}))

Root.defaultProps = {
  variant: 'Lato Body 1',
  textAlign: 'center',
}
