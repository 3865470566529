import { accordionDetailsClasses, Components, Theme } from '@mui/material'

const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  styleOverrides: {
    root: () => ({
      border: 0,
      boxShadow: 'none',

      [`.${accordionDetailsClasses.root}`]: {
        padding: 0,
      },

      '&:before': {
        display: 'none',
      },
    }),
  },
}

export default MuiAccordion
