import BaseLinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

export const Root = styled('div')({
  display: 'flex',
})

export const StepperStep = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  justifyContent: 'space-around',
})

export const LabelContainer = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
})

export const StepperStepIndex = styled('div', {
  shouldForwardProp: (prop) => prop !== 'filled',
})<{ filled: boolean }>(({ theme, filled }) => ({
  width: theme.spacing(2.25),
  height: theme.spacing(2.25),
  background: filled ? theme.palette.primary[800] : theme.palette.grey[400],
  color: theme.palette.grey[0],
  ...theme.typography['Lato Caption Bold'],
  lineHeight: theme.spacing(2.25),
  borderRadius: '50%',
  textAlign: 'center',
}))

export const LinearProgress = styled(BaseLinearProgress)(({ theme }) => ({
  flex: '1 1 auto',
  position: 'absolute',
  top: '50%',
  left: 'calc(-50% + 9px)',
  right: 'calc(50% + 9px)',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.grey[400],
  height: '1px',

  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary[800],
  },
}))

LinearProgress.defaultProps = {
  variant: 'determinate',
}
