import { InMemoryCacheConfig } from '@apollo/client'

const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        appointmentBundles: {
          keyArgs: ['id', 'kind'],
        },
      },
    },
  },
}

export default apolloCacheConfig
