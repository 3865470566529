import { PaginatedResponse } from '@dentalux/common'
import { addAuthInterceptors } from '@dentalux/security'
import { CredentialResponse } from '@dentalux/security'

import axios from 'axios'
import { DeletedReason } from 'src/@types/Patient'
import { TrustedDevice } from 'src/screens/AccountSettings/TrustedDevices/types'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HADES_URL,
})

addAuthInterceptors(http)

const bareHttp = axios.create({
  baseURL: process.env.NEXT_PUBLIC_HADES_URL,
})

export interface ChallengeResponse {
  challenge: string
  deviceReferenceId: string
}

export interface BiometricPreAuthResponse {
  challenge: string
  publicKey: string
}

export const getBiometricChallenge = async ({
  trustedDeviceReferenceId,
}: {
  trustedDeviceReferenceId: string | null
}): Promise<ChallengeResponse> => {
  const { data } = await http.get('/biometric/challenge', {
    params: { deviceReferenceId: trustedDeviceReferenceId },
  })

  return data
}

export const postRegisterPublicKey = async ({
  webAuthAttestation,
  trustedDeviceReferenceId,
}: {
  webAuthAttestation
  trustedDeviceReferenceId: string
}): Promise<void> => {
  await http.post(`/biometric/register/${trustedDeviceReferenceId}`, webAuthAttestation)
}

export const getBiometricPreAuthenticate = async ({
  trustedDeviceReferenceId,
}: {
  trustedDeviceReferenceId: string
}): Promise<BiometricPreAuthResponse> => {
  const { data } = await bareHttp.get(`/authenticate/device/${trustedDeviceReferenceId}`)

  return data
}

export const postBiometricAuthenticate = async ({
  trustedDeviceReferenceId,
  credential,
}: {
  trustedDeviceReferenceId: string
  credential
}): Promise<CredentialResponse> => {
  const { data, headers } = await bareHttp.post(`/authenticate/device/${trustedDeviceReferenceId}`, credential)

  return {
    token: headers.authorization,
    refreshToken: headers['x-refresh-token'],
    user: data,
  }
}

export const deleteTrustedDevice = async (referenceId: string) => {
  const { data } = await http.delete(`/devices/${referenceId}`)

  return data
}

export const getTrustedDevices = async (): Promise<PaginatedResponse<TrustedDevice>> => {
  const { data } = await http.get('/devices', {
    params: { size: 1000 },
  })

  return data
}

export const patchPreferredLanguage = async (userReferenceId: string, languageCode: string) => {
  const { data } = await http.patch(`/users/patient/${userReferenceId}/language`, {
    languageCode,
  })

  return data
}

export const deletePatientAccount = async (userReferenceId: string, deletedReason: DeletedReason) => {
  const { data } = await http.delete(`/users/patient/${userReferenceId}`, {
    params: { deletedReason },
  })

  return data
}
