import React from 'react'

import { useTranslation } from 'next-i18next'

import Dialog from 'src/components/Dialog'
import DialogButton from 'src/components/DialogButton'
import { useIdleTimer } from 'src/hooks/useIdleTimer'

import { LogoutPhase, useLogoutState } from '../providers/LogoutKindProvider'

import * as S from './IdleTimerDialog.styles'

const IdleTimerDialog = () => {
  const { t } = useTranslation()

  const { setState, state } = useLogoutState()

  const idleTimer = useIdleTimer({
    onPrompt: () => {
      setState(LogoutPhase.ABOUT_TO_LOGOUT)
    },
  })

  const handleStayLoggedIn = () => {
    setState(null)
    idleTimer.activate()
  }

  return (
    <Dialog
      open={state === LogoutPhase.ABOUT_TO_LOGOUT}
      icon={<S.TimeIcon />}
      title={t('b2b.idle_dialog.heading')}
      actions={<DialogButton>{t('b2b.idle_dialog.stayLoggedIn')}</DialogButton>}
      onClickInside={handleStayLoggedIn}
      onClose={handleStayLoggedIn}
    >
      {t('b2b.idle_dialog.subheading')}
    </Dialog>
  )
}

export default IdleTimerDialog
