import React, { ReactNode } from 'react'

import { useTranslation } from 'next-i18next'

import AccessibilityIcon from '@mui/icons-material/Accessibility'
import DraftsIcon from '@mui/icons-material/Drafts'

import { CalendarIcon } from 'src/components/icons/Calendar'
import { CostPlanIcon } from 'src/components/icons/CostPlan'
import { DentalIq } from 'src/components/icons/DentalIq'
import { InvoicesIcon } from 'src/components/icons/Invoices'
import { MedicalInfo } from 'src/components/icons/MedicalInfo'
import { Practitioner } from 'src/components/icons/Practitioner'
import { MenuIndicator, MenuIndicatorKind } from 'src/components/MenuIndicator/MenuIndicator'
import { setDefaultBookingContext, useBookingsDispatch } from 'src/components/providers/BookingsProvider'

import { useAnamnesisEvaluation } from './useAnamnesisEvaluation'
import { useCostPlanEvaluation } from './useCostplanEvaluation'
import { useInvoiceEvaluation } from './useInvoiceEvaluation'
import { usePractitionerEvaluation } from './usePractitionersEvaluation'

type MenuItem = { label: string; href: string; Icon: ReactNode; content?: ReactNode; effect?: () => void }

export const useMenu = () => {
  const { t } = useTranslation()
  const dispatch = useBookingsDispatch()

  const anamnesisEvaluation = useAnamnesisEvaluation({})
  const invoiceEvaluation = useInvoiceEvaluation({})
  const practitionersEvaluation = usePractitionerEvaluation({})
  const costPlanEvaluation = useCostPlanEvaluation({})

  const conditionalItem = (condition: boolean, item: MenuItem) => {
    return condition ? [item] : []
  }

  return [
    {
      label: t('b2c.menu.label.medicalData'),
      children: [
        {
          label: 'Dental IQ',
          href: '/dental-iq',
          Icon: <DentalIq />,
        },
        {
          label: t('b2c.menu.medicalHistory'),
          href: '/record',
          Icon: <MedicalInfo />,
        },
        {
          label: t('b2c.menu.appointments'),
          href: '/appointments',
          Icon: <CalendarIcon />,
          effect: () => {
            dispatch(setDefaultBookingContext())
          },
        },
        {
          content: (
            <MenuIndicator kind={MenuIndicatorKind.Warning} count={costPlanEvaluation.costPlanRecommendation ? 1 : 0}>
              {t('b2c.menu.costplans')}
            </MenuIndicator>
          ),
          href: costPlanEvaluation.costPlanRecommendation ? '/costplan' : '/costplan/all',
          Icon: <CostPlanIcon />,
        },
        {
          content: (
            <MenuIndicator
              kind={invoiceEvaluation.outdated.length > 0 ? MenuIndicatorKind.Error : MenuIndicatorKind.Warning}
              count={invoiceEvaluation.open.length + invoiceEvaluation.outdated.length}
            >
              {t('b2c.menu.invoices')}
            </MenuIndicator>
          ),
          href: '/invoice',
          Icon: <InvoicesIcon />,
        },
        ...conditionalItem(practitionersEvaluation?.all?.length > 0, {
          label: t('b2c.menu.practitioners'),
          href: `/practitioners/${practitionersEvaluation.favourite.length > 0 ? '' : 'all'}`,
          Icon: <Practitioner />,
        }),
        {
          content: (
            <MenuIndicator
              kind={
                anamnesisEvaluation.expired.length + anamnesisEvaluation.missing.length > 0
                  ? MenuIndicatorKind.Error
                  : MenuIndicatorKind.Warning
              }
              count={
                anamnesisEvaluation.expired.length +
                anamnesisEvaluation.missing.length +
                anamnesisEvaluation.problemWithFactoring.length
              }
            >
              {t('b2c.menu.anamnesis')}
            </MenuIndicator>
          ),
          href: '/anamnesis',
          Icon: <AccessibilityIcon />,
        },
      ],
    },
    {
      label: t('b2c.menu.label.help'),
      children: [
        {
          label: t('b2c.menu.contact'),
          href: '/contact',
          Icon: <DraftsIcon />,
        },
      ],
    },
  ]
}
