import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const LinkedIn = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 15" fill="none" sx={{ width: 16, height: 15 }}>
    <path
      d="M3.99935 14.667H0.666016V4.66703H3.99935V14.667ZM2.33335 3.3337C1.41135 3.3337 0.666016 2.58637 0.666016 1.66637C0.666016 0.746366 1.41268 0.000366211 2.33335 0.000366211C3.25202 0.000366211 3.99935 0.7477 3.99935 1.66637C3.99935 2.58637 3.25202 3.3337 2.33335 3.3337ZM15.9993 14.667H12.7947V9.80037C12.7947 8.6397 12.7727 7.14703 11.1287 7.14703C9.46001 7.14703 9.20335 8.41103 9.20335 9.71637V14.667H5.99935V4.6597H9.07535V6.02703H9.11868C9.54668 5.24037 10.5927 4.41103 12.1527 4.41103C15.3993 4.41103 15.9993 6.4837 15.9993 9.17837C15.9993 9.17837 15.9993 14.667 15.9993 14.667Z"
      fill={colors.grey[0]}
    />
  </SvgIcon>
)

export default LinkedIn
