import { ReadonlyURLSearchParams } from 'next/navigation'
import { NextRouter } from 'next/router'

import { SecurityConfig } from '@dentalux/security/lib/types'

import { LocalStorage } from 'src/@types/LocalStorage'
import { validateUrl } from 'src/hooks/useRedirectUrlHandling'

const getConfig = ({ search, u_s }: { search?: ReadonlyURLSearchParams; u_s?: string }): SecurityConfig => ({
  u_s,
  env: process.env.NEXT_PUBLIC_HADES_ENV as 'staging' | 'production',
  partiallyProtectedRoutes: {
    '/mfa': true,
    '/renew-consent': true,
    '/reset-phone-number': true,
  },
  successPath: (() => {
    const isWindowDefined = typeof window !== 'undefined'

    const queryRedirectUrl = isWindowDefined && search?.get(LocalStorage.RedirectUrl)

    const persistedRedirectUrl = isWindowDefined && localStorage.getItem(LocalStorage.RedirectUrl)

    const queryOrPersistedRedirectUrl = (() => {
      if (!isWindowDefined) {
        return ''
      }

      if (queryRedirectUrl) {
        return decodeURIComponent(queryRedirectUrl)
      }

      return persistedRedirectUrl
    })()

    const queryOrPersistedUrlValid = validateUrl(queryOrPersistedRedirectUrl)

    const redirectUrl = queryOrPersistedUrlValid ? queryOrPersistedRedirectUrl : ''

    if (redirectUrl) return redirectUrl

    const onboardingDismissed = isWindowDefined
      ? localStorage.getItem(LocalStorage.OnboardingDismissed)
        ? JSON.parse(localStorage.getItem(LocalStorage.OnboardingDismissed))
        : ''
      : ''

    if (onboardingDismissed) return '/'

    return '/onboarding'
  })(),
})

const createRouter = (router: NextRouter) => {
  const isBrowser = typeof window !== 'undefined'
  const search = router.asPath.split('?')[1]

  return {
    push: router.push,
    redirect: router.replace,
    pathname: router.pathname,
    search: search ? `?${search}` : '',
    hash: isBrowser ? window.location.hash : '',
    origin: isBrowser ? window.location.origin : '',
  }
}

const securityHelpers = {
  getConfig,
  createRouter,
}

export default securityHelpers
