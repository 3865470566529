import { gql } from '@apollo/client'

export const PERSON_QUERY = gql`
  query PERSON($personReferenceId: UUID!) {
    person(id: $personReferenceId) {
      referenceId
      firstName
      lastName
      gender
      phoneNumber
      birthday
      email
      summary {
        hasImages
        hasJawFindings
        hasPatientHistory
        hasPsiDiagnosis
      }
    }
  }
`
