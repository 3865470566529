import { PaginatedResponse } from '@dentalux/common'
import { addAuthInterceptors } from '@dentalux/security'
import { Form, Optins } from '@dentalux/ui-library-core/cjs/custom/components/ana-toolbox'

import axios from 'axios'
import { Anamnesis, AnamnesisPerClinic } from 'src/@types/Anamnesis'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ANA_URL,
})

addAuthInterceptors(http)

export const getAnamnesis = async ({
  personReferenceId,
  clinicReferenceId,
  size,
}: {
  personReferenceId: string
  clinicReferenceId?: string
  size?: number
}): Promise<PaginatedResponse<Anamnesis>> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/anamnesis`, {
    params: { clinicReferenceId, size },
  })

  return data
}

export const getAnamnesisListing = async ({
  personReferenceId,
}: {
  personReferenceId: string
}): Promise<Record<string, AnamnesisPerClinic>> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/clinics/anamnesis `)

  return data
}

export const postFactoringAgreement = async ({
  personReferenceId,
  language,
  clinicReferenceId,
}: {
  personReferenceId: string
  language: string
  clinicReferenceId: string
}) => {
  const { data } = await http.post(`/external/persons/${personReferenceId}/factoring-agreements`, {
    clinicReferenceId,
    language: language.toUpperCase(),
  })

  return data
}

export const postFactoringAgreementSign = async ({
  personReferenceId,
  language,
  clinicReferenceId,
  factoringAgreementReferenceId,
  signatureEncoded,
}: {
  personReferenceId: string
  language: string
  clinicReferenceId: string
  factoringAgreementReferenceId: string
  signatureEncoded: string
}) => {
  const { data } = await http.post(
    `/external/persons/${personReferenceId}/factoring-agreements/${factoringAgreementReferenceId}/signatures`,
    {
      clinicReferenceId,
      signatureEncoded,
      language: language.toUpperCase(),
    }
  )

  return data
}

export const getAnamnesisById = async ({
  personReferenceId,
  anamnesisReferenceId,
}: {
  personReferenceId: string
  anamnesisReferenceId: string
}): Promise<Anamnesis> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/anamnesis/${anamnesisReferenceId}`)

  return data
}

export const postAnamnesis = async ({
  personReferenceId,
  formData,
  signatureEncoded,
  clinicReferenceId,
}: {
  personReferenceId: string
  formData: Partial<Form>
  signatureEncoded: string
  clinicReferenceId: string
}): Promise<Anamnesis> => {
  const { data } = await http.post(`/external/persons/${personReferenceId}/anamnesis`, {
    ...formData,
    signatureEncoded,
    clinicReferenceId,
  })

  return data
}

export const getOptins = async ({
  personReferenceId,
}: {
  personReferenceId: string
}): Promise<{ clinicReferenceId: string; optIns: Optins }[]> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/opts`)

  return data
}

export const postOptins = async ({
  personReferenceId,
  clinicReferenceId,
  ...body
}: {
  personReferenceId: string
  clinicReferenceId: string
  confidentialityAgreementAccepted?: boolean
  marketingCommunicationConsentAccepted?: boolean
}) => {
  const { data } = await http.post(`/external/persons/${personReferenceId}/clinics/${clinicReferenceId}/opts`, body)

  return data
}
