import React, { ReactNode } from 'react'

import { useTranslation } from 'next-i18next'
import Head from 'next/head'

import AppBar from 'src/components/AppBar/AppBar'
import Footer from 'src/components/Footer'
import IdleTimerDialog from 'src/components/IdleTimerDialog'
import { useTabs } from 'src/hooks/useTabs'
import useTranslationSwitch from 'src/hooks/useTranslationSwitch'

import TabContentTemplate from '../TabContentTemplate'

import * as S from './InternalTemplate.styles'

export type InternalTemplateProps = {
  pageTitle?: string
  className?: string
  fullWidth?: boolean
  Prefooter?: ReactNode | ReactNode[]
  tabsConfig?: {
    path: string
    key: string
  }
  children?: ReactNode | ReactNode[]
}

const InternalTemplate = ({
  className,
  fullWidth,
  pageTitle,
  children,
  Prefooter,
  tabsConfig,
}: InternalTemplateProps) => {
  const tabs = useTabs()
  const { t } = useTranslation()

  useTranslationSwitch({ syncLang: true })

  const Main = (
    <>
      <S.ContentContainer withTabs={!!tabsConfig} fullWidth={fullWidth} className={className}>
        {children}
      </S.ContentContainer>

      <Footer Prefooter={Prefooter} />
    </>
  )

  const Content = tabsConfig ? (
    <TabContentTemplate basePath={tabsConfig.path} tabs={tabs[tabsConfig.key]}>
      {Main}
    </TabContentTemplate>
  ) : (
    Main
  )

  return (
    <>
      <Head>
        <title>{pageTitle ? `${pageTitle} | ${t('b2c.head.title')}` : t('b2c.head.title')}</title>
      </Head>

      <AppBar />

      {Content}

      <IdleTimerDialog />
    </>
  )
}

export default InternalTemplate
