import { createElement } from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { Components, svgIconClasses, Theme } from '@mui/material'

import EmptyStar from 'src/components/icons/EmptyStar'
import Star from 'src/components/icons/Star'

const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {
    icon: createElement(Star),
    emptyIcon: createElement(EmptyStar),
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      [`& .${svgIconClasses.root}`]: {
        ...(ownerState.size === 'small' && {
          width: theme.spacing(2),
          height: theme.spacing(2),
        }),
        ...(ownerState.size === 'medium' && {
          width: theme.spacing(3),
          height: theme.spacing(3),
        }),
        ...(ownerState.size === 'large' && {
          width: theme.spacing(4),
          height: theme.spacing(4),
        }),
      },
    }),
    icon: {
      color: colors.grey[0],
    },
  },
}

export default MuiRating
