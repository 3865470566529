import { useMutation } from 'react-query'

import api from 'api'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

export const useSupplementaryInsuranceOffer = () => {
  const personReferenceId = usePersonId()

  return useMutation(
    ({ languageCode, consentGiven }: { languageCode: string; consentGiven: boolean }) =>
      api.insurance.postSupplementaryOfferRequest({ personReferenceId, languageCode, consentGiven }),
    { useErrorBoundary: false }
  )
}
