import React, { ReactNode } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LanguageIcon from '@mui/icons-material/Language'
import BaseMenuList from '@mui/material/MenuList'

import AppBar from 'src/components/BaseAppBar'
import Footer from 'src/components/Footer'
import D21Logo from 'src/components/icons/D21Logo'
import Langs from 'src/components/Langs'
import Menu from 'src/components/Menu'
import { DataTestIds } from 'src/config/dataTestIds'
import useTranslationSwitch from 'src/hooks/useTranslationSwitch'

import * as S from './ExternalTemplate.styles'

const ExternalTemplate = ({
  children,
  className,
  bar = {
    show: true,
    onlyLogo: false,
  },
  href = '/login',
}: {
  children: ReactNode | ReactNode[]
  className?: string
  bar?: {
    show: boolean
    onlyLogo: boolean
  }
  href?: string
}) => {
  const { currentLanguage, setActiveLang } = useTranslationSwitch({})

  return (
    <S.Wrapper className={className}>
      {bar.show && (
        <AppBar
          middleContent={
            <S.Link data-testid={DataTestIds.HeaderLogo} href={href} passHref>
              <D21Logo />
            </S.Link>
          }
          rightContent={
            !bar.onlyLogo && (
              <Menu
                justifyContent="right"
                trigger={
                  <>
                    <LanguageIcon />
                    <ArrowDropDownIcon />
                  </>
                }
              >
                <BaseMenuList>
                  <Langs currentLanguage={currentLanguage} changeLanguage={setActiveLang} />
                </BaseMenuList>
              </Menu>
            )
          }
        />
      )}
      <S.Content>{children}</S.Content>
      <Footer />
    </S.Wrapper>
  )
}

export default ExternalTemplate
