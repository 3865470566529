import React, { createContext, useContext, useState } from 'react'

import { LogoutKind } from '@dentalux/security'

type State = { state: LogoutKind | LogoutPhase; setState: (logoutKind: LogoutKind | LogoutPhase) => void }

export enum LogoutPhase {
  ABOUT_TO_LOGOUT = 'about_to_logout',
}

const LogoutKindContext = createContext<State | undefined>(undefined)

export const LogoutKindProvider = ({ children }) => {
  const [state, setState] = useState<LogoutKind | LogoutPhase | null>(null)

  return <LogoutKindContext.Provider value={{ state, setState }}>{children}</LogoutKindContext.Provider>
}

export const useLogoutState = () => {
  const context = useContext(LogoutKindContext)

  if (context === undefined) {
    throw new Error('useLogoutKind must be used within a LogoutKindProvider')
  }

  return context
}
