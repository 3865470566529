import { styled } from '@mui/material/styles'

export const Overlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'none',
  background: theme.palette.background.default,
  zIndex: '9998',
}))

export const Loading = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'none',
  zIndex: 9999,
})
