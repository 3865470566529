import { Components, Theme } from '@mui/material'

const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[300],
      borderColor: theme.palette.grey[300],
    }),
  },
}

export default MuiDivider
