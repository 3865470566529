import React from 'react'

import Router from 'next/router'

import Loader from 'src/components/Loader/Loader'

import * as S from './PageLoader.styles'

Router.events.on('routeChangeComplete', () => {
  document.body.classList.remove('loading')
  window.scrollTo(0, 0)
  window.scroll({ top: 0, left: 0 })
})

Router.events.on('routeChangeError', () => {
  document.body.classList.remove('loading')
})

Router.events.on('routeChangeStart', (url) => {
  if (url.includes('/verifyphone') || url.includes('/mfa')) {
    document.body.classList.add('loading')
  }
})

export const PageLoader = () => (
  <>
    <S.Overlay id="loading-overlay" />
    <S.Loading id="loading">
      <Loader size="xl" />
    </S.Loading>
  </>
)
