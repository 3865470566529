import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { PaginatedResponse } from 'src/@types/Pagination'
import { IgnoredReason, RecommendationData, RecommendationSpecialityAggregation } from 'src/@types/Recommendation'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_RECOMMENDATION_BACKEND_URL,
})

addAuthInterceptors(http)

export const getPersonRecommendations = async ({
  personReferenceId,
  ...params
}: {
  personReferenceId: string
  speciality?: RecommendationSpecialityAggregation
  size?: number
  page?: number
  sortBy?: string
  sortDirection?: 'ASC' | 'DESC'
  includeIgnored?: boolean
  includeNonIgnored?: boolean
  isDentalPsiBased?: boolean
  isJawFindingBased?: boolean
  isCostPlanBased?: boolean
}): Promise<PaginatedResponse<RecommendationData>> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/recommendations`, { params })

  return data
}

export const ignoreRecommendation = async ({
  referenceId,
  personReferenceId,
  ...body
}: {
  referenceId: string
  personReferenceId: string
  ignoredReason: IgnoredReason
  hadTreatmentAlreadyAt?: string
}) => {
  const { data } = await http.patch(
    `/external/persons/${personReferenceId}/recommendations/${referenceId}/ignored`,
    body
  )

  return data
}
