import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Facebook = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 9 16" fill="none" sx={{ width: 9, height: 16 }}>
    <path
      d="M3.00065 16.0004V8.00037H0.333984V5.3337H3.00065V3.9017C3.00065 1.19037 4.32132 0.000366211 6.57465 0.000366211C7.65398 0.000366211 8.22465 0.0803662 8.49465 0.117033V2.66703H6.95798C6.00132 2.66703 5.66732 3.1717 5.66732 4.19437V5.3337H8.47065L8.08998 8.00037H5.66732V16.0004H3.00065Z"
      fill={colors.grey[0]}
    />
  </SvgIcon>
)

export default Facebook
