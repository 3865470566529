import { useQuery } from 'react-query'

import { RecommendationSpecialityAggregation } from 'src/@types/Recommendation'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'
import { PaginationModes, usePaginationConfiguration } from '../usePaginationConfiguration'

export const usePersonRecommendation = ({
  perPage,
  refetchInterval,
  enabled = true,
  ...rest
}: {
  perPage: number
  enabled?: boolean
  speciality?: RecommendationSpecialityAggregation
  refetchInterval?: number
  isDentalPsiBased?: boolean
  isJawFindingBased?: boolean
  isCostPlanBased?: boolean
}) => {
  const personReferenceId = usePersonId()

  const {
    config: [page, size],
    ...pagination
  } = usePaginationConfiguration({
    perPage,
    mode: PaginationModes.INFINITE,
  })

  const query = useQuery(
    ['recommendations', personReferenceId, rest],
    () =>
      api.recommendation.getPersonRecommendations({
        personReferenceId,
        page,
        size,
        ...rest,
      }),
    {
      refetchInterval,
      enabled: !!personReferenceId && enabled,
    }
  )

  return {
    ...query,
    ...pagination,
    perPage,
  }
}
