import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

export const Photo = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 17" fill="none" sx={{ width: props.width || 24, height: props.width || 24 }}>
    <path
      d="M8.78904 0.200012C8.18264 0.200012 7.62899 0.541987 7.35779 1.08439L7.04216 1.71564C6.77096 2.25724 6.21651 2.60001 5.61091 2.60001H2.19998C1.31598 2.60001 0.599976 3.31601 0.599976 4.20001V14.6C0.599976 15.484 1.31598 16.2 2.19998 16.2H19.8C20.684 16.2 21.4 15.484 21.4 14.6V4.20001C21.4 3.31601 20.684 2.60001 19.8 2.60001H16.389C15.7826 2.60001 15.229 2.25804 14.9578 1.71564L14.6422 1.08439C14.371 0.542787 13.8165 0.200012 13.2109 0.200012H8.78904ZM3.79998 1.00001C3.35838 1.00001 2.99998 1.35841 2.99998 1.80001H5.39998C5.39998 1.35841 5.04158 1.00001 4.59998 1.00001H3.79998ZM11 3.40001C14.088 3.40001 16.6 5.91201 16.6 9.00001C16.6 12.088 14.088 14.6 11 14.6C7.91198 14.6 5.39998 12.088 5.39998 9.00001C5.39998 5.91201 7.91198 3.40001 11 3.40001ZM18.2 4.20001C18.6416 4.20001 19 4.55841 19 5.00001C19 5.44161 18.6416 5.80001 18.2 5.80001C17.7584 5.80001 17.4 5.44161 17.4 5.00001C17.4 4.55841 17.7584 4.20001 18.2 4.20001ZM11 5.00001C9.93911 5.00001 8.92169 5.42144 8.17155 6.17159C7.4214 6.92173 6.99998 7.93915 6.99998 9.00001C6.99998 10.0609 7.4214 11.0783 8.17155 11.8284C8.92169 12.5786 9.93911 13 11 13C12.0608 13 13.0783 12.5786 13.8284 11.8284C14.5785 11.0783 15 10.0609 15 9.00001C15 7.93915 14.5785 6.92173 13.8284 6.17159C13.0783 5.42144 12.0608 5.00001 11 5.00001Z"
      fill="#424242"
    />
  </SvgIcon>
)
