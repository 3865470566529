import React, { createContext, useContext, useReducer } from 'react'

import { useLocalStorage } from '@dentalux/common'

import { LocalStorage } from 'src/@types/LocalStorage'

export enum ActionTypes {
  warningDismiss = 'setWarningDismiss',
  beforeInstallPromptDismiss = 'setBeforeInstallPromptDismiss',
  isBeforeInstallPromptOpen = 'setIsBeforeInstallPromptOpen',
  reset = 'setDefault',
}

export enum AuthorizedContextResetMode {
  Full = 'FULL',
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export enum WarningDismissKind {
  Anamnesis = 'ANAMNESIS',
  Invoice = 'INVOICE',
  CostPlan = 'COSTPLAN',
}

type SetWarningDismissAction = {
  type: ActionTypes.warningDismiss
  payload: { kind: WarningDismissKind }
}
type SetBeforeInstallPromptDismissAction = { type: ActionTypes.beforeInstallPromptDismiss; payload: boolean }
type SetIsBeforeInstallPromptOpenAction = { type: ActionTypes.isBeforeInstallPromptOpen; payload: boolean }
type ResetAction = { type: ActionTypes.reset; payload: AuthorizedContextResetMode }

type StaticState = {
  beforeInstallPromptDismissed?: boolean
}

type DynamicState = {
  anamnesisWarningDismissed?: boolean
  costPlanWarningDismissed?: boolean
  invoicesWarningDismissed?: boolean
  isBeforeInstallPromptOpened?: boolean
}

type State = StaticState & DynamicState

type Dispatch = (
  action:
    | SetWarningDismissAction
    | SetBeforeInstallPromptDismissAction
    | SetIsBeforeInstallPromptOpenAction
    | ResetAction
) => void

const AuthorizedContext = createContext<State | undefined>(undefined)

const AuthorizedDispatchContext = createContext<Dispatch | undefined>(undefined)

export const defaultUnauthorizedStaticState: StaticState = {
  beforeInstallPromptDismissed: false,
}

export const defaultUnauthorizedDynamicState: DynamicState = {
  anamnesisWarningDismissed: false,
  invoicesWarningDismissed: false,
  costPlanWarningDismissed: false,
  isBeforeInstallPromptOpened: false,
}

const authorizedReducer =
  (persist) =>
  (
    state: State,
    action:
      | SetWarningDismissAction
      | SetBeforeInstallPromptDismissAction
      | SetIsBeforeInstallPromptOpenAction
      | ResetAction
  ) => {
    const finalize = (nextState: State) => {
      persist(nextState)
      return nextState
    }

    switch (action.type) {
      case ActionTypes.warningDismiss: {
        const dismissed = (() => {
          if (action.payload.kind === WarningDismissKind.Anamnesis) return 'anamnesisWarningDismissed'
          if (action.payload.kind === WarningDismissKind.Invoice) return 'invoicesWarningDismissed'

          return 'costPlanWarningDismissed'
        })()

        return finalize({ ...state, [dismissed]: true })
      }

      case ActionTypes.beforeInstallPromptDismiss:
        return finalize({ ...state, beforeInstallPromptDismissed: action.payload })

      case ActionTypes.isBeforeInstallPromptOpen:
        return finalize({ ...state, isBeforeInstallPromptOpened: action.payload })

      case ActionTypes.reset:
        if (action.payload === AuthorizedContextResetMode.Full)
          return finalize({ ...defaultUnauthorizedDynamicState, ...defaultUnauthorizedStaticState })

        if (action.payload === AuthorizedContextResetMode.Dynamic)
          return finalize({ ...state, ...defaultUnauthorizedDynamicState })

        return finalize({ ...state, ...defaultUnauthorizedStaticState })

      default:
        throw new Error(`Unhandled action`)
    }
  }

export const AuthorizedProvider = ({ children }) => {
  const [value, setValue] = useLocalStorage(LocalStorage.Authorized, {
    ...defaultUnauthorizedStaticState,
    ...defaultUnauthorizedDynamicState,
  })

  const [state, dispatch] = useReducer(authorizedReducer(setValue), { ...value })

  return (
    <AuthorizedContext.Provider value={state}>
      <AuthorizedDispatchContext.Provider value={dispatch}>{children}</AuthorizedDispatchContext.Provider>
    </AuthorizedContext.Provider>
  )
}

export const useAuthorizedContext = () => {
  const context = useContext(AuthorizedContext)

  if (context === undefined) {
    throw new Error('useAuthorizedContext must be used within a AuthorizedContext')
  }

  return context
}

export const useAuthorizedDispatch = () => {
  const context = useContext(AuthorizedDispatchContext)

  if (context === undefined) {
    throw new Error('useAuthorizedDispatch must be used within a AuthorizedDispatchContext')
  }

  return context
}

export const setBeforeInstallPromptDismiss = (payload: boolean): SetBeforeInstallPromptDismissAction => ({
  type: ActionTypes.beforeInstallPromptDismiss,
  payload,
})

export const setIsBeforeInstallPromptOpen = (payload: boolean): SetIsBeforeInstallPromptOpenAction => ({
  type: ActionTypes.isBeforeInstallPromptOpen,
  payload,
})

export const reset = (payload: AuthorizedContextResetMode = AuthorizedContextResetMode.Dynamic): ResetAction => ({
  type: ActionTypes.reset,
  payload,
})

export const setWarningDismiss = (payload: { kind: WarningDismissKind }): SetWarningDismissAction => ({
  type: ActionTypes.warningDismiss,
  payload,
})
