import * as Yup from 'yup'

/**
 * Allow only mobile phone numbers
 */
export function mobilePhoneValidation(
  this: Yup.StringSchema<string | undefined>,
  message?: string
): Yup.StringSchema<string | undefined> {
  return this.test('mobilePhoneNumber', '', async function (this: Yup.TestContext, value: string | null | undefined) {
    const { path, createError } = this

    if (!value) return true

    const parsePhoneNumberFromString = (await import('libphonenumber-js/mobile')).parsePhoneNumberFromString

    try {
      const isValid = parsePhoneNumberFromString(value || '')?.isValid()

      if (isValid) return isValid
    } catch (_) {}

    return createError({ path, message })
  })
}
