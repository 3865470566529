import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useAnamnesisPerClinic = ({
  useErrorBoundary,
  enabled = true,
}: {
  useErrorBoundary?: boolean
  enabled?: boolean
}) => {
  const personReferenceId = usePersonId()

  return useQuery(['anamnesis_listing', personReferenceId], () => api.ana.getAnamnesisListing({ personReferenceId }), {
    enabled: Boolean(personReferenceId) && enabled,
    cacheTime: 5 * 60 * 1000,
    useErrorBoundary,
  })
}
