import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { Practitioner } from 'src/@types/Practitioner'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_EMPLOYEE_URL,
})

addAuthInterceptors(http)

export const getPractitioner = async (practitionerReferenceId: string): Promise<Practitioner> => {
  const { data } = await http.get(`/external/employees/v1/${practitionerReferenceId}`)

  return data
}
