import React, { ReactNode, useRef } from 'react'

import { useTranslation } from 'next-i18next'

import { useOnScreen } from 'src/hooks/useOnScreen'

import Facebook from '../icons/Facebook'
import Insta from '../icons/Insta'
import LinkedIn from '../icons/LinkedIn'

import * as S from './Footer.styles'

const Footer = ({ Prefooter: PrefooterElement }: { Prefooter?: ReactNode | ReactNode[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const selfRef = useRef<HTMLDivElement>(null)

  const Self = (
    <S.Wrapper ref={selfRef}>
      <S.Content>
        <S.Column>
          <S.Link href={`https://patient21.com/${language}/impressum`}>{t('common.footer.imprint')}</S.Link>
          <S.Link href={`/${language}/information`}> {t('common.footer.information')}</S.Link>
          <S.Link href={`/${language}/terms`}> {t('common.footer.tos')}</S.Link>
          <S.Link href={`/${language}/contact`}> {t('common.footer.contact')}</S.Link>
          <S.Link href={`/${language}/privacy`}> {t('common.footer.privacy')}</S.Link>
        </S.Column>
        <S.Column>
          <S.Socials>
            <S.SocialLink href="https://www.facebook.com/patient21DOTcom">
              <Facebook />
            </S.SocialLink>
            <S.SocialLink href="https://www.linkedin.com/company/patient21">
              <LinkedIn />
            </S.SocialLink>
            <S.SocialLink href="https://www.instagram.com/patient21_com/">
              <Insta />
            </S.SocialLink>
          </S.Socials>
          <S.Brand>© Patient21, {new Date().getFullYear()}</S.Brand>
        </S.Column>
      </S.Content>
    </S.Wrapper>
  )

  const isVisible = useOnScreen(selfRef, Self)

  const Prefooter = (() => {
    if (!PrefooterElement) return null

    return (
      <>
        <S.InView isVisible={isVisible}>{PrefooterElement}</S.InView>
        <S.Fixed isVisible={!isVisible}>{PrefooterElement}</S.Fixed>
      </>
    )
  })()

  return (
    <>
      {Prefooter}
      {Self}
    </>
  )
}

export default Footer
