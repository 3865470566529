import { useQuery } from 'react-query'

import api from 'api'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

const useToothFindingsSummary = ({ enabled = true }: { enabled?: boolean }) => {
  const personId = usePersonId()

  const { data, isLoading } = useQuery(
    ['medical_records_tooth_findings_summary', personId],
    () => api.medicalRecords.getToothFindingsSummary(personId),
    {
      enabled: enabled && Boolean(personId),
    }
  )

  return {
    isLoading,
    data: data?.content || [],
  }
}

export default useToothFindingsSummary
