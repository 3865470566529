import { MuiTooltip as MuiTooltipBase } from '@dentalux/ui-library-core/cjs/themes/tooltip'

import { Components, Theme } from '@mui/material'

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    enterTouchDelay: 0,
  },
  ...MuiTooltipBase,
}

export default MuiTooltip
