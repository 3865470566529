import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const D21Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 55 24" sx={{ width: 55, height: 24 }}>
      <path
        d="M35.2526 0C39.913 0 43.1489 2.8832 43.1489 7.2745C43.1489 10.425 41.3685 12.9391 37.7765 14.7833L34.2814 16.5929C32.987 17.2972 31.4337 18.4372 31.4337 19.8787H43.1489V23.7334H27.1944V20.4816C27.1944 16.6935 30.2686 14.2143 32.4374 13.1068L36.0628 11.2296C37.9069 10.291 38.8455 8.95057 38.8455 7.24131C38.8455 5.06139 37.4542 3.65484 35.2536 3.65484C32.826 3.65484 31.4347 5.56574 31.499 8.41451H27.1944C27.0969 3.21811 30.3011 0 35.2526 0Z"
        fill={colors.grey[0]}
      />
      <path
        d="M54.9999 3.32059V0.250122H51.2754V1.59201C51.2751 2.28138 51.0105 2.9424 50.5399 3.42983C50.0693 3.91727 49.4311 4.19124 48.7656 4.19157H46.2546V7.84938H50.6267C50.6594 7.84938 50.691 7.84493 50.7237 7.84418V23.7314H54.9987V3.31936L54.9999 3.32059Z"
        fill={colors.grey[0]}
      />
      <path
        d="M4.78646 0C6.0559 0 7.27336 0.522368 8.17099 1.45219C9.06863 2.38201 9.57291 3.64312 9.57291 4.95809V9.91617H4.78646C3.51701 9.91617 2.29956 9.3938 1.40192 8.46398C0.504286 7.53416 0 6.27305 0 4.95809H0C0 3.64312 0.504286 2.38201 1.40192 1.45219C2.29956 0.522368 3.51701 0 4.78646 0V0Z"
        fill={colors.grey[0]}
      />
      <path
        d="M0 19.0418C0 17.7268 0.504287 16.4657 1.40192 15.5359C2.29956 14.6061 3.51701 14.0837 4.78645 14.0837H9.57291V19.0418C9.57291 20.3568 9.06862 21.6179 8.17099 22.5477C7.27335 23.4775 6.0559 23.9999 4.78645 23.9999V23.9999C3.51701 23.9999 2.29956 23.4775 1.40192 22.5477C0.504287 21.6179 0 20.3568 0 19.0418Z"
        fill={colors.grey[0]}
      />
      <path
        d="M18.383 23.9999C17.1135 23.9999 15.8961 23.4775 14.9984 22.5477C14.1008 21.6179 13.5965 20.3568 13.5965 19.0418L13.5965 14.0837L18.383 14.0837C19.6524 14.0837 20.8699 14.6061 21.7675 15.5359C22.6651 16.4657 23.1694 17.7268 23.1694 19.0418C23.1694 20.3568 22.6651 21.6179 21.7675 22.5477C20.8699 23.4775 19.6524 23.9999 18.383 23.9999Z"
        fill={colors.grey[0]}
      />
      <path
        d="M23.1694 4.95809C23.1694 6.27305 22.6651 7.53416 21.7675 8.46398C20.8699 9.39381 19.6524 9.91617 18.383 9.91617H13.5965V4.95809C13.5965 3.64312 14.1008 2.38201 14.9984 1.45219C15.8961 0.522369 17.1135 0 18.383 0C19.6524 0 20.8699 0.522369 21.7675 1.45219C22.6651 2.38201 23.1694 3.64312 23.1694 4.95809V4.95809Z"
        fill={colors.grey[0]}
      />
    </SvgIcon>
  )
}

export default D21Logo
