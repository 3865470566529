import { usePersonId } from 'src/components/providers/PersonIdProvider'

export const useSentryUserId = () => {
  const id = usePersonId()

  ;(async () => {
    try {
      const Sentry = await import('@sentry/react')

      if (
        process.env.NEXT_PUBLIC_SENTRY_DNS &&
        process.env.NEXT_PUBLIC_SENTRY_ENV &&
        process.env.NEXT_PUBLIC_ENV !== 'preview' &&
        id
      ) {
        Sentry.setUser({ id: id })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  })()
}
