import React, { ReactNode } from 'react'

import * as S from './MenuIndicator.styles'

export enum MenuIndicatorKind {
  Error = 'ERROR',
  Warning = 'WARNING',
}

export const MenuIndicator = ({
  children,
  count,
  kind,
}: {
  children: ReactNode | ReactNode[]
  count: number
  kind: MenuIndicatorKind
}) =>
  count === 0 ? (
    <>{children}</>
  ) : (
    <S.Wrapper>
      {children}
      <S.Indicator kind={kind}>{count}</S.Indicator>
    </S.Wrapper>
  )
