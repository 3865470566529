import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useBaseProfileImage = ({ enabled = true }: { enabled?: boolean }) => {
  const personReferenceId = usePersonId()

  return useQuery(
    ['base-profile-image', personReferenceId],
    () => api.patient.getBaseProfileImage({ personReferenceId }),
    {
      enabled: Boolean(personReferenceId) && enabled,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )
}
