import { Components, dividerClasses, Theme } from '@mui/material'

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[800],
      ...theme.typography['Lato Emphasized 2'],
      padding: theme.spacing(2, 3, 2, 2),
      background: theme.palette.common.white,

      '&:hover': {
        background: theme.palette.primary[50] + '!important',
      },

      '&.Mui-selected': {
        background: theme.palette.primary[100] + '!important',
      },
      [`&+.${dividerClasses.root}`]: {
        margin: theme.spacing(0, 2),
      },
    }),
  },
}

export default MuiMenuItem
