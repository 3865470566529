import { gql } from '@apollo/client'

import { ClinicsFragments } from './clinics'
import { PractitionerFragments } from './practitioner'

export const CLINIC_PRACTITIONERS_QUERY = gql`
  query CLINIC_PRACTITIONERS($personReferenceId: UUID!, $kind: ClinicPractitionerKind!) {
    clinicPractitioners(id: $personReferenceId, kind: $kind) {
      data {
        clinic {
          ...Clinic
        }
        practitioners(personReferenceId: $personReferenceId) {
          ...Practitioner
          favorite(personReferenceId: $personReferenceId)
          rating(personReferenceId: $personReferenceId) {
            ...PractitionerRating
          }
        }
      }
    }
  }
  ${ClinicsFragments.Clinic}
  ${PractitionerFragments.Practitioner}
  ${PractitionerFragments.PractitionerRating}
`
