;(async () => {
  try {
    const Sentry = await import('@sentry/react')

    if (
      process.env.NEXT_PUBLIC_SENTRY_DNS &&
      process.env.NEXT_PUBLIC_SENTRY_ENV &&
      process.env.NEXT_PUBLIC_ENV !== 'preview'
    ) {
      Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_DNS,
        integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0,
        ignoreErrors: [
          'The token is invalid.',
          'SwipeableDrawer',
          'Load failed',
          'Unexpected EOF',
          'timeout exceeded',
          ErrorCodes.AdapterCommError,
          ErrorCodes.AdapterProcessError,
        ].map((error) => new RegExp(error)),
        environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
        release: process.env.NEXT_PUBLIC_COMMIT_SHA,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: process.env.NEXT_PUBLIC_ENV === 'production' ? 0.01 : 0,
        beforeSend: (event, hint) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const orgExc: any = hint?.originalException

          if (orgExc?.response?.status === 401) {
            return null
          }

          if (orgExc?.response?.data) {
            event.extra = { ...event.extra, responseBody: orgExc?.response?.data }
          }

          return event
        },
      })
    }
  } catch (error) {
    console.error('Error:', error)
  }
})()

import React, { ReactNode, ComponentType, useEffect, useState } from 'react'

import { useQueryClient } from 'react-query'

import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { ErrorCodes } from 'src/config/errorCodes'
import useClinic from 'src/hooks/api/useClinic'
import useIsMobile from 'src/hooks/useIsMobile'
import { useRedirectUrlHandling } from 'src/hooks/useRedirectUrlHandling'

import Error from '../icons/Error'
import { setDefaultBookingContext, useBookings, useBookingsDispatch } from '../providers/BookingsProvider'

import * as S from './ErrorBoundary.styles'

const SentryErrorBoundary = dynamic(() => import('@sentry/react').then((mod) => mod.ErrorBoundary))

export enum ErrorBoundaryLevel {
  Generic = 'GENERIC',
  Component = 'COMPONENT',
}

type FallbackProps = {
  level: ErrorBoundaryLevel
  dashboardButton?: boolean
  title?: string
  makeCompact?: boolean
  message?: string
  children?: ReactNode | ReactNode[]
  reset?: () => void
  clinicNumber?: string
}

export const Fallback = ({
  title,
  level,
  makeCompact,
  children,
  message,
  reset,
  clinicNumber,
  dashboardButton,
}: FallbackProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const dispatch = useBookingsDispatch()

  const router = useRouter()

  useEffect(() => {
    if (!reset) return

    router.events.on('routeChangeComplete', reset)

    return () => {
      router.events.off('routeChangeComplete', reset)
    }
  }, [])

  const compact = isMobile || makeCompact

  const path = router.asPath

  const pageTitle = (() => {
    if (level === ErrorBoundaryLevel.Component) return undefined

    if (path === '/anamnesis') return t('b2c.error.anaFetching')

    return undefined
  })()

  return (
    <S.Root compact={compact}>
      <Error />

      <S.Title compact={compact}>{title || pageTitle || t('b2c.error.generic.title')}</S.Title>
      {message !== null && (
        <S.Message
          compact={compact}
          dangerouslySetInnerHTML={{
            __html: (() => {
              if (message) return message

              if (clinicNumber) return t('b2c.error.generic.callMessage', { number: clinicNumber })

              return t('b2c.error.generic.message')
            })(),
          }}
        />
      )}

      {children}

      {dashboardButton && (
        <S.Button
          onClick={() => {
            if (clinicNumber) {
              dispatch(setDefaultBookingContext())
            }

            document.body.classList.add('loading')
            router.push('/')
          }}
        >
          {t('b2c.error.generic.dashboard')}
        </S.Button>
      )}

      {level === ErrorBoundaryLevel.Generic && (
        <S.NextSteps compact={compact} dangerouslySetInnerHTML={{ __html: t('b2c.error.generic.nextSteps') }} />
      )}
    </S.Root>
  )
}

export const ErrorBoundary = ({
  children,
  level,
  title,
  Wrapper,
  dashboardButton,
}: {
  children?: ReactNode | ReactNode[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Wrapper?: ComponentType<any>
} & FallbackProps) => {
  useRedirectUrlHandling({ shouldRead: false })

  const { pathname, push, prefetch } = useRouter()

  const [count, setCount] = useState<number>(0)

  const { clinicReferenceId, appointmentBundle } = useBookings()

  const appointmentsError = pathname.includes('/appointments')

  const client = useQueryClient()

  const { isLoading, data: clinicData } = useClinic({
    clinicReferenceId: clinicReferenceId || appointmentBundle?.clinic?.referenceId,
    enabled: appointmentsError,
  })

  const loginRedirect = '/login?error=session_expired'

  useEffect(() => {
    prefetch(loginRedirect)
  }, [])

  return (
    <SentryErrorBoundary
      onReset={() => {
        client.clear()
        setCount(count + 1)
      }}
      key={`dismissed-errors-${count}`}
      fallback={({ resetError, error }) => {
        if (isLoading) return null

        if (error.message === 'The token is invalid.') {
          push(loginRedirect).then(() => resetError())

          return null
        }

        return (
          <>
            {Wrapper ? (
              <Wrapper>
                <Fallback
                  dashboardButton={dashboardButton}
                  clinicNumber={appointmentsError ? clinicData?.phoneNumber : undefined}
                  reset={resetError}
                  title={title}
                  level={level}
                />
              </Wrapper>
            ) : (
              <Fallback dashboardButton={dashboardButton} title={title} level={level} />
            )}
          </>
        )
      }}
    >
      {children}
    </SentryErrorBoundary>
  )
}
