import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Insta = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16" fill="none" sx={{ width: 16, height: 16 }}>
    <path
      d="M4.66536 0.000366211C2.0927 0.000366211 0 2.095 0 4.66833V11.335C0 13.9077 2.09464 16.0004 4.66797 16.0004H11.3346C13.9073 16.0004 16 13.9057 16 11.3324V4.66573C16 2.09306 13.9054 0.000366211 11.332 0.000366211H4.66536ZM12.6667 2.66703C13.0347 2.66703 13.3333 2.9657 13.3333 3.3337C13.3333 3.7017 13.0347 4.00037 12.6667 4.00037C12.2987 4.00037 12 3.7017 12 3.3337C12 2.9657 12.2987 2.66703 12.6667 2.66703ZM8 4.00037C10.206 4.00037 12 5.79437 12 8.00037C12 10.2064 10.206 12.0004 8 12.0004C5.794 12.0004 4 10.2064 4 8.00037C4 5.79437 5.794 4.00037 8 4.00037ZM8 5.3337C7.29276 5.3337 6.61448 5.61465 6.11438 6.11475C5.61428 6.61484 5.33333 7.29312 5.33333 8.00037C5.33333 8.70761 5.61428 9.38589 6.11438 9.88598C6.61448 10.3861 7.29276 10.667 8 10.667C8.70724 10.667 9.38552 10.3861 9.88562 9.88598C10.3857 9.38589 10.6667 8.70761 10.6667 8.00037C10.6667 7.29312 10.3857 6.61484 9.88562 6.11475C9.38552 5.61465 8.70724 5.3337 8 5.3337Z"
      fill={colors.grey[0]}
    />
  </SvgIcon>
)

export default Insta
