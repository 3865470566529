import { styled, Typography, Button as ButtonBase } from '@mui/material'

export const Button = styled(ButtonBase)({
  maxWidth: 374,
  width: '100%',
})

Button.defaultProps = {
  size: 'lg',
}

export const Root = styled('section', {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact: boolean }>(({ theme, compact }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 552,
  margin: 'auto',
  textAlign: 'center',

  ...(!compact && {
    paddingTop: theme.spacing(4),
  }),
}))

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact: boolean }>(({ theme, compact }) => ({
  display: 'block',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(compact ? 3 : 4),
  ...theme.typography[compact ? 'DM Serif H3' : 'DM Serif H2'],
}))

export const Message = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact: boolean }>(({ theme, compact }) => ({
  marginBottom: theme.spacing(compact ? 3 : 4),
  color: theme.palette.grey[700],
  ...theme.typography[compact ? 'Lato Body 1' : 'Lato H5'],

  '> a': {
    color: theme.palette.primary.main,
    ...theme.typography[compact ? 'Lato Link 1' : 'Lato H5 Bold'],
  },
}))

export const NextSteps = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'compact',
})<{ compact: boolean }>(({ theme, compact }) => ({
  marginTop: theme.spacing(compact ? 3 : 4),

  color: theme.palette.grey[700],
  ...theme.typography[compact ? 'Lato Body 2' : 'Lato Body 1'],

  a: {
    color: theme.palette.primary.main,
    ...theme.typography[compact ? 'Lato Link 2' : 'Lato Link 1'],
  },
}))
