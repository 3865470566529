import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

export const CostPlanIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
      <path
        d="M4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM19 11H9V9H19V11ZM15 15H9V13H15V15ZM19 7H9V5H19V7Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
