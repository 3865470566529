import { useQuery } from 'react-query'

import { Anamnesis } from 'src/@types/Anamnesis'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useAnamnesisById = ({
  anamnesisReferenceId,
  onSuccess,
  enabled = true,
}: {
  anamnesisReferenceId: string
  onSuccess?: (res: Anamnesis) => void
  enabled?: boolean
}) => {
  const personReferenceId = usePersonId()

  return useQuery(
    ['anamnesis', personReferenceId, anamnesisReferenceId],
    () =>
      api.ana.getAnamnesisById({ personReferenceId, anamnesisReferenceId }).then((res) => {
        onSuccess && onSuccess(res)
        return res
      }),
    {
      enabled: enabled && Boolean(personReferenceId && anamnesisReferenceId),
    }
  )
}
