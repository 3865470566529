import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { useLocalStorage } from '@dentalux/common'

import { LocalStorage } from 'src/@types/LocalStorage'
import { Nullable } from 'src/@types/Nullable'
import { RecommendationData, RecommendationSpecialityAggregation } from 'src/@types/Recommendation'

type State = {
  active: Nullable<RecommendationSpecialityAggregation>
  shouldRedetermine: boolean
}

const RecommendationContext = createContext<State>({
  active: null,
  shouldRedetermine: true,
})

const defaultState: State = {
  active: null,
  shouldRedetermine: true,
}

type RecommendationProviderProps = {
  children?: React.ReactNode
}

export enum ActionTypes {
  setActive = 'setActive',
  setShouldRedetermine = 'setShouldRedetermine',
}

type SetActiveAction = { type: ActionTypes.setActive; payload: RecommendationSpecialityAggregation }
type SetShouldRedetermineAction = { type: ActionTypes.setShouldRedetermine; payload: boolean }

const bookingsReducer = (persist) => (state: State, action: SetActiveAction | SetShouldRedetermineAction) => {
  const finalize = (nextState: State) => {
    persist(nextState)
    return nextState
  }

  switch (action.type) {
    case ActionTypes.setActive:
      return finalize({ ...state, active: action.payload })

    case ActionTypes.setShouldRedetermine:
      return finalize({ ...state, shouldRedetermine: action.payload })

    default:
      throw new Error(`Unhandled action`)
  }
}

type Dispatch = (action: SetActiveAction | SetShouldRedetermineAction) => void

const RecommendationDispatchContext = createContext<Dispatch | undefined>(undefined)

export const RecommendationProvider = ({ children }: RecommendationProviderProps) => {
  const [value, setValue] = useLocalStorage(LocalStorage.Recommendations, defaultState)

  const [state, dispatch] = useReducer(bookingsReducer(setValue), { ...value })

  return (
    <RecommendationContext.Provider value={state}>
      <RecommendationDispatchContext.Provider value={dispatch}>{children}</RecommendationDispatchContext.Provider>
    </RecommendationContext.Provider>
  )
}

export const useRecommendationDispatch = () => {
  const context = useContext(RecommendationDispatchContext)

  if (context === undefined) {
    throw new Error('useRecommendationDispatch must be used within a RecommendationContext')
  }

  return context
}

export const useRecommendation = ({
  recommendations,
}: {
  recommendations: {
    dental: RecommendationData[]
    other: RecommendationData[]
  }
}) => {
  const context = useContext(RecommendationContext)

  const dispatch = useRecommendationDispatch()

  const selectActive = () => {
    if (recommendations.dental.length > 0) {
      return RecommendationSpecialityAggregation.Dental
    }

    if (recommendations.other.length > 0) {
      return RecommendationSpecialityAggregation.Other
    }

    return null
  }

  const entries = recommendations[context.active === RecommendationSpecialityAggregation.Dental ? 'dental' : 'other']

  useEffect(() => {
    if (context.shouldRedetermine) {
      dispatch(setActive(selectActive()))
    } else {
      dispatch(setShouldRedetermine(true))
    }
  }, [])

  useEffect(() => {
    if (entries.length === 0) {
      dispatch(setActive(selectActive()))
    }
  }, [entries.length])

  if (context === undefined) {
    throw new Error('useRecommendation must be used within a RecommendationProvider')
  }

  return { ...context, entries }
}

export const setActive = (payload: RecommendationSpecialityAggregation): SetActiveAction => ({
  type: ActionTypes.setActive,
  payload,
})

export const setShouldRedetermine = (payload: boolean): SetShouldRedetermineAction => ({
  type: ActionTypes.setShouldRedetermine,
  payload,
})
