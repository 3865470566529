import React from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

export const DentalIq = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none" sx={{ width: 24, height: 24 }}>
      <path
        d="M13.7778 7.3C12.4444 7.3 12.2222 8.02 12 8.02C11.7778 8.02 11.5556 7.3 10.2222 7.3C8.41422 7.3 8 8.5792 8 10.4829C8 11.272 8.288 11.8384 8.56711 12.3866C8.75689 12.759 8.93556 13.1109 9.03511 13.5429C9.096 13.8069 9.14978 14.0814 9.20267 14.3531C9.448 15.6054 9.70133 16.9 10.6667 16.9C11.5556 16.9 11.5556 15.8906 11.5556 15.46V14.5C11.5556 14.0574 11.7853 13.9 12 13.9C12.2147 13.9 12.4444 14.0574 12.4444 14.5V15.46C12.4444 15.9986 12.4444 16.9 13.3333 16.9C14.2898 16.9 14.5431 15.6194 14.788 14.381C14.8436 14.0987 14.8996 13.8146 14.9644 13.5434C15.064 13.1114 15.2427 12.7595 15.4324 12.387C15.712 11.8389 16 11.272 16 10.4829C16 8.5792 15.5858 7.3 13.7778 7.3Z"
        fill="#424242"
      />
      <path
        d="M23.0385 12.0098C23.0385 18.0795 18.118 23 12.0482 23C5.97848 23 1.05798 18.0795 1.05798 12.0098C1.05798 5.94002 5.97848 1.01952 12.0482 1.01952C18.118 1.01952 23.0385 5.94002 23.0385 12.0098ZM4.35506 12.0098C4.35506 16.2586 7.79941 19.7029 12.0482 19.7029C16.2971 19.7029 19.7414 16.2586 19.7414 12.0098C19.7414 7.76094 16.2971 4.31659 12.0482 4.31659C7.79941 4.31659 4.35506 7.76094 4.35506 12.0098Z"
        fill="#BDBDBD"
      />
      <path
        d="M12.0482 2.66805C12.0482 1.75759 12.7904 1.00686 13.6906 1.14292C15.3379 1.39189 16.9145 2.01336 18.2969 2.96874C20.1334 4.23806 21.5399 6.0366 22.329 8.12499C23.1181 10.2134 23.2525 12.4926 22.7143 14.6592C22.1761 16.8259 20.9908 18.7773 19.3162 20.2537C17.6416 21.73 15.557 22.6614 13.34 22.9238C11.123 23.1862 8.87858 22.7672 6.90556 21.7226C4.93255 20.6779 3.32447 19.0571 2.29534 17.076C1.52074 15.5848 1.10178 13.9427 1.06123 12.2772C1.03907 11.367 1.8769 10.7248 2.78018 10.8389C3.68347 10.953 4.30466 11.7838 4.38525 12.6907C4.4737 13.686 4.75572 14.66 5.2212 15.5561C5.9416 16.9429 7.06725 18.0775 8.44836 18.8087C9.82948 19.54 11.4005 19.8333 12.9525 19.6496C14.5044 19.4659 15.9636 18.814 17.1358 17.7805C18.3081 16.747 19.1378 15.3811 19.5145 13.8644C19.8912 12.3477 19.7972 10.7523 19.2448 9.29042C18.6924 7.82855 17.7079 6.56957 16.4223 5.68105C15.5916 5.10692 14.6606 4.70505 13.6842 4.49255C12.7946 4.29893 12.0482 3.57851 12.0482 2.66805Z"
        fill="#424242"
      />
    </SvgIcon>
  )
}
