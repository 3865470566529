import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FOOTER_HEIGHT = 20

export const Link = styled('a')(({ theme }) => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
  ...theme.typography['Lato Body 2'],
  display: 'block',
  textDecoration: 'none',

  '& + &': {
    marginTop: theme.spacing(1),
  },
}))

Link.defaultProps = {
  target: '_blank',
}

export const Brand = styled(Typography)({})

Brand.defaultProps = {
  variant: 'Lato Emphasized 2',
}

export const Socials = styled('article')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

export const SocialLink = styled(Link)(({ theme }) => ({
  '& + &': {
    marginTop: 0,
    marginLeft: theme.spacing(1.5),
  },
}))

export const Wrapper = styled('footer')(({ theme }) => ({
  background: theme.palette.primary[700],
  height: theme.spacing(FOOTER_HEIGHT),
  padding: `${theme.spacing(2)}`,
  zIndex: 1000,
}))

export const Fixed = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '100%',
  position: 'fixed',
  bottom: 0,
  zIndex: isVisible ? 110 : -9999,
  opacity: isVisible ? 1 : 0,
}))

export const InView = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
  zIndex: isVisible ? 110 : -9999,
  opacity: isVisible ? 1 : 0,
}))

export const FixedDummy = styled('div', {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height: number }>(({ height }) => ({
  height: height + 'px',
}))

export const MeasuredELement = styled('div')({
  position: 'absolute',
  top: -1000,
  left: -1000,
  width: '100%',
  pointerEvents: 'none',
  zIndex: -10000,
})

export const Content = styled('section')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

export const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.common.white,
}))
