import { parse, isValid, isBefore } from 'date-fns'
import * as Yup from 'yup'

import { BirthdayValidationMessageObject } from '../@types/yup/BirthdayValidationMessageObject'
import * as dateHelpers from '../helpers/date'

const defaultBirthdayErrorMessages: BirthdayValidationMessageObject = {
  invalidDate: 'Please insert a valid date. For example 31.12.1990',
  inPast: 'Please insert a date in the past.',
  empty: 'This field is required.',
  adult: 'Please enter a valid date. The insurance holder’s age must be above 18 years.',
  maxAge: 'Please choose an age between 1 and 120 years',
}

/*
 * getBirthdayFormat
 *
 * @param {string} birthday - A string preferrable in the `dd.MM.yy` or `dd.MM.yyyy` format.
 * @returns {string} - A date format to be used by date-fns. The format can be either `dd.MM.yy` (if the birthday has length 8) or `dd.MM.yyyy` for any other size.
 */
const getBirthdayFormat = (birthday: string) => {
  if (birthday.length === dateHelpers.SHORT_BDAY_FORMAT.length) return dateHelpers.SHORT_BDAY_FORMAT

  return dateHelpers.LONG_BDAY_FORMAT
}

/* This function validates a string which represents
 * a date in the DD.MM.YY or DD.MM.YYYY format
 */

export function dateValidation(
  this: Yup.StringSchema<string | undefined>,
  message: Partial<BirthdayValidationMessageObject> | undefined
): Yup.StringSchema<string | undefined> {
  return this.test('birthday', '', function (this: Yup.TestContext, value: string | null | undefined) {
    const { path, createError } = this

    if (!value) return createError({ path, message: message?.empty || defaultBirthdayErrorMessages.empty })

    const trimmedValue = value.trim()

    if (
      trimmedValue.length !== dateHelpers.SHORT_BDAY_FORMAT.length &&
      trimmedValue.length !== dateHelpers.LONG_BDAY_FORMAT.length
    )
      return createError({ path, message: message?.invalidDate || defaultBirthdayErrorMessages.invalidDate })

    const format = getBirthdayFormat(trimmedValue)

    const date = parse(value, format, new Date())

    const now = new Date()

    const isDateValid = isValid(date)

    const isPast = isBefore(date, now)

    if (!isDateValid)
      return createError({ path, message: message?.invalidDate || defaultBirthdayErrorMessages.invalidDate })

    if (!isPast) return createError({ path, message: message?.inPast || defaultBirthdayErrorMessages.inPast })

    return isDateValid
  })
}

export function formatDateValidation(
  this: Yup.StringSchema<string | undefined>,
  format: string,
  message?: string | undefined
): Yup.StringSchema<string | undefined> {
  return this.test(`formatDate`, message, function (value) {
    const trimmedValue = value?.trim()

    if (!trimmedValue) {
      return this.createError({ path: this.path, message })
    }

    const isDateValid = isValid(parse(trimmedValue, format, new Date()))

    if (!isDateValid) {
      return this.createError({ path: this.path, message })
    }

    return isDateValid
  })
}
