import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

export const usePostHog = (apiKey: string, name?: string): { distinctId: string } => {
  const router = useRouter()
  const [distinctId, setDistinctId] = useState('')

  useEffect(() => {
    let handleRouteChange = undefined

    async function initPosthog() {
      const posthog = (await import('posthog-js')).posthog

      posthog.init(
        apiKey,
        {
          api_host: process.env.NEXT_PUBLIC_PH_INSTANCE_ADDRESS,
          loaded: (instance) => {
            setDistinctId(instance.sessionManager.persistence.props.distinct_id)

            if (process.env.NEXT_PUBLIC_ENV !== 'production') instance.opt_out_capturing()
          },
          persistence: 'memory',
        },
        name
      )

      // Track page views
      handleRouteChange = () => posthog.capture('$pageview')
      router.events.on('routeChangeComplete', handleRouteChange)
    }

    initPosthog()

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return { distinctId }
}
