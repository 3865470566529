import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { Invoice } from 'src/@types/Invoice'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_INVOICES_URL,
})

addAuthInterceptors(http)

export const getInvoices = async ({ personReferenceId }: { personReferenceId: string }): Promise<Invoice[]> => {
  const { data } = await http.get(`/external/bfs/v1/invoices`, {
    params: { personReferenceId },
  })

  return data
}

export const getInvoice = async ({
  id,
  personReferenceId,
}: {
  id: string
  personReferenceId: string
}): Promise<string> => {
  const { data } = await http.get(`/external/bfs/v1/invoices/${id}/pdf`, {
    params: {
      personReferenceId,
    },
  })

  return data
}
