import React, { createContext, useContext, useMemo, useState, useCallback, useEffect } from 'react'

import DialogRoot, { DialogNames } from '../dialogs/DialogRoot'

import { LogoutPhase, useLogoutState } from './LogoutKindProvider'

type DialogProviderState = {
  isOpen: boolean
  dialogType: DialogNames | null
  dialogProps?: Record<string, unknown>
}

type ContextProps = DialogProviderState & {
  openDialog: <T extends Record<string, unknown>>(dialogType: DialogNames, dialogProps?: T) => void
  closeDialog: () => void
}

const DialogContext = createContext<ContextProps>({
  isOpen: false,
  dialogType: null,
  dialogProps: {},
  openDialog: () => {},
  closeDialog: () => {},
})

const defaultState: DialogProviderState = {
  isOpen: false,
  dialogType: null,
  dialogProps: {},
}

type DialogProviderProps = {
  children?: React.ReactNode
  initialState?: DialogProviderState
}

export const DialogProvider = ({ children, initialState = defaultState }: DialogProviderProps) => {
  const [state, setState] = useState<DialogProviderState>(initialState)

  const openDialog = useCallback(
    <T extends Record<string, unknown>>(dialogType: DialogNames, dialogProps?: T) => {
      setState({ isOpen: true, dialogType, dialogProps })
    },
    [setState]
  )

  const closeDialog = useCallback(() => setState(initialState), [setState, initialState])

  const value: ContextProps = useMemo(() => ({ ...state, openDialog, closeDialog }), [state, openDialog, closeDialog])

  const { state: logoutState } = useLogoutState()

  useEffect(() => {
    if (logoutState !== LogoutPhase.ABOUT_TO_LOGOUT && logoutState !== null) {
      closeDialog()
    }
  }, [logoutState, closeDialog])

  return (
    <DialogContext.Provider value={value}>
      {logoutState !== LogoutPhase.ABOUT_TO_LOGOUT && <DialogRoot />}
      {children}
    </DialogContext.Provider>
  )
}

export const useDialog = () => {
  const context = useContext(DialogContext)

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return context
}
