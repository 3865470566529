import { useEffect } from 'react'

import { useRouter } from 'next/router'

import { reset, useAnaDispatch } from 'src/components/providers/AnaProvider'

import { AnaMode } from './useAnaNavigation'

export const useResetAna = () => {
  const { asPath } = useRouter()

  const modesRegex = Object.values(AnaMode).join('|')

  const anaLocationRegex = new RegExp(`.*\\/anamnesis\\/[^\\/]*\\/(?:${modesRegex})\\/*`)

  const dispatch = useAnaDispatch()

  useEffect(() => {
    if (!anaLocationRegex.test(asPath.toLowerCase())) {
      dispatch(reset())
    }
  })
}
