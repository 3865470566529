import { Backend } from '@dentalux/i18next-plugins'

import { PostProcessorModule } from 'i18next'
import { Remarkable } from 'remarkable'
import RemarkableReactRenderer from 'remarkable-react'

const md = new Remarkable({
  linkTarget: '_blank',
})

md.renderer = new RemarkableReactRenderer()

const markdownRenderer = (str: string) => {
  return md.renderInline(str)
}

const markdownModule: PostProcessorModule = {
  type: 'postProcessor',
  name: 'markdown',
  process: function (value: string) {
    return markdownRenderer(value)
  },
}

const markdownBlockModule: PostProcessorModule = {
  type: 'postProcessor',
  name: 'markdownBlock',
  process: function (value: string) {
    return md.render(value)
  },
}

export default {
  reloadOnPrerender: true,
  i18n: {
    locales: ['en', 'de'],
    defaultLocale: 'de',
    backend: {
      applicationName: 'patient-account',
      contentfulConfig: {
        usePreview: process.env.NODE_ENV === 'development',
      },
    },
    ns: 'common',
  },
  use: [Backend, markdownModule, markdownBlockModule],
  serializeConfig: false,
  react: {
    useSuspense: false,
  },
}
