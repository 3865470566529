import React from 'react'

import { ButtonProps } from '@mui/material'

import * as S from './DialogButton.styles'

const DialogButton = ({ children, variant = 'borderless', size = 'md', ...rest }: ButtonProps) => {
  return (
    <S.Root variant={variant} size={size} disabled={Boolean(rest.disabled)} {...rest}>
      {children}
    </S.Root>
  )
}

export default DialogButton
