import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
import format from 'date-fns/format'
import fpFormat from 'date-fns/fp/format'
import parse from 'date-fns/parse'

export const DEFAULT_FORMAT = 'dd.MM.yyyy'
export const SHORT_BDAY_FORMAT = 'dd.MM.yy'
export const LONG_BDAY_FORMAT = 'dd.MM.yyyy'
export const MONTH_YEAR_FORMAT = 'MM.yyyy'
export const BDAY_REGEX = /\d{2}\.\d{2}\.\d{2,4}/
export const DEFAULT_TIMEZONE = 'Europe/Berlin'

type Options = {
  locale?: Locale
}

export function isInGermanyTz(): boolean {
  const berlinTimeRepresentation = formatInTimeZone(new Date(), DEFAULT_TIMEZONE, 'p')
  const localTimeRepresentation = format(new Date(), 'p')

  return berlinTimeRepresentation === localTimeRepresentation
}

export const formatDay = (date: Date | number) => formatInTimeZone(date, DEFAULT_TIMEZONE, 'dd.MM.')

export const formatDefault = (date: Date | number) => format(date, DEFAULT_FORMAT)

export const toISODate = fpFormat('yyyy-MM-dd')

export const formatTime = (date: Date, options: Options) => {
  const showTimezone = !isInGermanyTz()

  const time = options.locale.code === 'de' ? "HH:mm 'Uhr'" : 'p'

  return formatInTimeZone(date, DEFAULT_TIMEZONE, showTimezone ? `${time} zzz` : time)
}

const shortFormat = 'dd MMM yyyy'

export const formatShort = (date: Date, options: Options) => {
  return formatInTimeZone(date, DEFAULT_TIMEZONE, shortFormat, options)
}

export const formatShortWithTime = (date: Date, options: Options) => {
  return formatInTimeZone(date, DEFAULT_TIMEZONE, `${shortFormat}, HH:ss`, options)
}

export const formatMedium = (date: Date, options: Options) => {
  const dayOfWeek = options.locale.code === 'de' ? 'EE' : 'EEE'

  return formatInTimeZone(date, DEFAULT_TIMEZONE, `${dayOfWeek}, dd MMM yyyy`, options)
}

export const formatLong = (date: Date, options: Options) => {
  return formatInTimeZone(date, DEFAULT_TIMEZONE, 'EEEE, dd MMMM yyyy', options)
}

/*
 * formatBirthday
 *
 * Converts a string on the format `dd.MM.yy` or `dd.MM.yyyy` into `yyyy-MM-dd`
 */
export const formatBirthday = (date: string) => {
  const valueTrimmed = date.trim()

  if (!valueTrimmed) return valueTrimmed
  if (!BDAY_REGEX.test(valueTrimmed)) throw new Error('Wrong birthday format')

  const format = valueTrimmed.length === 8 ? SHORT_BDAY_FORMAT : LONG_BDAY_FORMAT

  return toISODate(parse(valueTrimmed, format, new Date()))
}
