import React from 'react'

import { ApolloClient, ApolloProvider as OgApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { LocalStorage } from 'src/@types/LocalStorage'
import apolloCacheConfig from 'src/config/apolloCache'

/* A custom `fetch` which adds the graphql operation name to the request
 * query params in order to make the requests easier to debug. */
const fetchWithDebug = (uri: string, options: RequestInit) => {
  try {
    const { operationName } = JSON.parse(options.body as string)

    return fetch(`${uri}?op=${operationName}`, options)
  } catch {
    return fetch(uri, options)
  }
}

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  fetch: fetchWithDebug,
})

const authLink = setContext((_, { headers }) => {
  const auth = localStorage.getItem(LocalStorage.Auth) ? JSON.parse(localStorage.getItem(LocalStorage.Auth)) : ''

  if (!auth?.token) console.warn('[ApolloProvider] Token not found')

  return {
    headers: {
      ...headers,
      authorization: auth?.token,
    },
  }
})

const link = authLink.concat(httpLink)

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ ...apolloCacheConfig }),
})

const ApolloProvider = ({ children }) => {
  return <OgApolloProvider client={apolloClient}>{children}</OgApolloProvider>
}

export default ApolloProvider
