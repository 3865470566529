import React, { createContext, useContext, useMemo, useState, useCallback } from 'react'

import { Snackbar, SnackbarProps } from '@dentalux/ui-library-core/cjs/components/snackbar'

import useIsDesktop from 'src/hooks/useIsDesktop'

type SnackbarProviderState = Omit<SnackbarProps, 'onClose'>

type ContextProps = SnackbarProviderState & {
  openSnackbar: (props: Omit<SnackbarProviderState, 'open'>) => void
  closeSnackbar: () => void
}

const SnackbarContext = createContext<ContextProps>({
  open: false,
  openSnackbar: () => {},
  closeSnackbar: () => {},
})

const defaultState: SnackbarProviderState = {
  open: false,
  message: '',
}

type SnackbarProviderProps = {
  children?: React.ReactNode
  initialState?: SnackbarProviderState
}

export const SnackbarProvider = ({ children, initialState = defaultState }: SnackbarProviderProps) => {
  const [state, setState] = useState<SnackbarProviderState>(initialState)
  const isDesktop = useIsDesktop()

  const openSnackbar = useCallback(
    (props: SnackbarProps) => {
      setState({
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'bottom', horizontal: isDesktop ? 'left' : 'center' },
        ...props,
        open: true,
      })
    },
    [setState]
  )

  const closeSnackbar = useCallback(() => setState(initialState), [setState, initialState])

  const value: ContextProps = useMemo(
    () => ({ ...state, openSnackbar, closeSnackbar }),
    [state, openSnackbar, closeSnackbar]
  )

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar {...state} onClose={closeSnackbar} />
      {children}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)

  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  return context
}
