import { useTranslation } from 'next-i18next'

import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'

const useDateFnsConfig = () => {
  const { i18n } = useTranslation()

  const config = {
    locale: i18n.language === 'de' ? deLocale : enLocale,
  }

  return config
}

export default useDateFnsConfig
