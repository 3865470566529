import React from 'react'

import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { SecurityProvider } from '@dentalux/security'

import { HooksRunner } from 'src/components/HooksRunner/HooksRunner'
import { PageLoader } from 'src/components/PageLoader/PageLoader'
import RouteGuard from 'src/components/RouteGuard'
import nextI18nextConfig from 'src/config/nextI18next'
import securityHelpers from 'src/config/security'
import { usePostHog } from 'src/hooks/usePostHog'
import AllProviders from 'src/providers/AllProviders'

import 'src/styles/custom.css'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  const { distinctId } = usePostHog(process.env.NEXT_PUBLIC_PH_PROJECT_API_KEY)

  const search = useSearchParams()

  if (typeof window === 'undefined') {
    return <></>
  }

  return (
    <SecurityProvider
      config={securityHelpers.getConfig({ search, u_s: distinctId || 'not enabled' })}
      router={securityHelpers.createRouter(router)}
    >
      <AllProviders>
        <RouteGuard path={router.pathname}>
          <>
            <Component {...pageProps} />
            <PageLoader />
            <HooksRunner />
          </>
        </RouteGuard>
      </AllProviders>
    </SecurityProvider>
  )
}

export default appWithTranslation(MyApp, nextI18nextConfig)
