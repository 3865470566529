import { QueryHookOptions, useQuery } from '@apollo/client'
import { ClinicPractitioner } from 'src/@types/ClinicPractitioner'
import { Nullable } from 'src/@types/Nullable'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { CLINIC_PRACTITIONERS_QUERY } from '../queries/clinicPractitioners'
import { ClinicPractitionersVariables } from '../types/ClinicPractitionersVariables'
import { PageInfo } from '../types/PageInfo'

type ClinicPractitionersResponse = { clinicPractitioners: Nullable<{ pageInfo: PageInfo; data: ClinicPractitioner[] }> }

export enum ClinicPractitionerKind {
  All = 'ALL',
  Favorite = 'FAVORITE',
  Inactive = 'INACTIVE',
  Recent = 'RECENT',
}

const useClinicPractitioners = ({
  options,
}: {
  options: QueryHookOptions<ClinicPractitionersResponse, ClinicPractitionersVariables>
}) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<ClinicPractitionersResponse>(CLINIC_PRACTITIONERS_QUERY, {
    ...options,
    variables: { personReferenceId, ...options.variables },
    skip: options.skip || !personReferenceId,
  })

  return { data: data?.clinicPractitioners, ...rest }
}

export default useClinicPractitioners
