import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { DentalIQ } from 'src/@types/DentalIQ'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PATIENT_SCORPIO_URL,
})

addAuthInterceptors(http)

export const getPersonScores = async ({ personReferenceId }: { personReferenceId: string }): Promise<DentalIQ[]> => {
  const { data } = await http.get(`/v1/external/persons/${personReferenceId}/scores/dental-iqs`)

  return data
}
