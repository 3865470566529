import { useAnamnesisPerClinic } from './api/useAnamnesisPerClinic'

export const useAnamnesisEvaluation = ({
  enabled,
  useErrorBoundary = false,
}: {
  useErrorBoundary?: boolean
  enabled?: boolean
}) => {
  const { data, isLoading } = useAnamnesisPerClinic({ enabled, useErrorBoundary })

  const all = Object.values(data || {}).sort(
    (a, b) => Number(new Date(b.createdAtDateTime)) - Number(new Date(a.createdAtDateTime))
  )

  const expired = all
    .filter((ana) => ana.isExpired || (ana.anamnesisReferenceId && !ana.isValid))
    .map((el) => el.anamnesisReferenceId)

  const missing = all.filter((ana) => !ana.anamnesisReferenceId).map((el) => el.clinicReferenceId)

  const problemWithFactoring = all.filter((ana) => {
    const { signed, expired, valid } = ana.latestFactoringAgreementForClinic || {}

    return ana.isValid && (expired || !signed || !valid)
  })

  return {
    isLoading,
    mostRecentValidAna: all.filter((a) => a.isValid).find((a) => a),
    all: all || [],
    expired: expired || [],
    missing: missing || [],
    problemWithFactoring: problemWithFactoring || [],
  }
}
