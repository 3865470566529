import { QueryHookOptions, useQuery } from '@apollo/client'
import { Nullable } from 'src/@types/Nullable'
import { Person } from 'src/@types/Person'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { PERSON_QUERY } from '../queries/person'

type PersonResponse = { person: Nullable<Person> }

const usePerson = (options: QueryHookOptions<PersonResponse>) => {
  const personReferenceId = usePersonId()

  const { data, ...rest } = useQuery<PersonResponse>(PERSON_QUERY, {
    ...options,
    variables: { personReferenceId, ...options.variables },
    skip: options.skip || !personReferenceId,
  })

  return { data: data?.person, ...rest }
}

export default usePerson
