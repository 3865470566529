import { useMutation, useQueryClient } from 'react-query'

import api from 'api'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

export const useOptinsMutation = ({ clinicReferenceId }: { clinicReferenceId: string }) => {
  const personReferenceId = usePersonId()
  const client = useQueryClient()

  return useMutation(
    ({
      confidentialityAgreementAccepted,
      marketingCommunicationConsentAccepted,
    }: {
      confidentialityAgreementAccepted?: boolean
      marketingCommunicationConsentAccepted?: boolean
    }) =>
      api.ana.postOptins({
        personReferenceId,
        clinicReferenceId,
        ...(confidentialityAgreementAccepted && { confidentialityAgreementAccepted }),
        ...(marketingCommunicationConsentAccepted && { marketingCommunicationConsentAccepted }),
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['anamnesis_listing', personReferenceId])
      },
    }
  )
}
