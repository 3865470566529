import React, { ReactNode, useEffect } from 'react'

import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'

import { Form, formatBirthday, Optins } from '@dentalux/ui-library-core/cjs/custom/components/ana-toolbox'
import { Translate } from '@dentalux/ui-library-core/cjs/custom/components/ana/components/translate/translate'
import { InsuranceType } from '@dentalux/ui-library-core/cjs/type/Appointment'

import { DialogNames } from 'src/components/dialogs/DialogRoot'
import { EndBar } from 'src/components/EndBar/EndBar'
import { useAnaContext, useAnaDispatch, setAnswers, setLoading, useInit } from 'src/components/providers/AnaProvider'
import { useDialog } from 'src/components/providers/DialogProvider'
import StepperProgress from 'src/components/StepperProgress'
import { SubTitle } from 'src/components/Subtitle/SubTitle'
import usePerson from 'src/graphql/hooks/usePerson'
import { stripBase } from 'src/helpers/signature'
import { useAnamnesisById } from 'src/hooks/api/useAnamnesisById'
import { useAnamnesisMutation } from 'src/hooks/api/useAnamnesisMutation'
import { useInsuranceStatus } from 'src/hooks/api/useInsuranceStatus'
import { useOptins } from 'src/hooks/api/useOptins'
import { useOptinsMutation } from 'src/hooks/api/useOptinsMutation'
import { useSupplementaryInsuranceOffer } from 'src/hooks/api/useSupplementaryInsuranceOffer'
import useToothFindingsSummary from 'src/hooks/api/useToothFindingsSummary'
import { useAnaNavigation, AnaMode } from 'src/hooks/useAnaNavigation'
import InternalTemplate from 'src/templates/InternalTemplate'

import { PersonalInformationContent } from '../PersonalInformation/PersonalInformation'

import * as S from './Anamnesis.styles'

const EndBarDataAttr = 'ana-end-bar-data-attr'

export enum AnaSection {
  PersonalInformation = 'personal-information',
  AllergiesAndMedications = 'allergies-and-medications',
  ContactDetails = 'contact-details',
  Insurance = 'insurance',
  Miscellaneous = 'miscellaneous',
  Preconditions1 = 'preconditions-1',
  Preconditions2 = 'preconditions-2',
  Preconditions3 = 'preconditions-3',
  Wishes1 = 'wishes-1',
  Wishes2 = 'wishes-2',
  Wishes3 = 'wishes-3',
  Wishes4 = 'wishes-4',
  Consents = 'consents',
  FactoringAgreement = 'factoring-agreement',
  Success = 'success',
}

const titles = {
  [AnaSection.PersonalInformation]: 'category.general_information',
  [AnaSection.ContactDetails]: 'category.general_information',
  [AnaSection.Insurance]: 'category.general_information',
  [AnaSection.Miscellaneous]: 'category.general_information',
  [AnaSection.Preconditions1]: 'category.anamnesis',
  [AnaSection.Preconditions2]: 'category.anamnesis',
  [AnaSection.Preconditions3]: 'category.anamnesis',
  [AnaSection.AllergiesAndMedications]: 'subcategory.allergies_and_medications',
  [AnaSection.Wishes1]: 'category.additional_details',
  [AnaSection.Wishes2]: 'category.additional_details',
  [AnaSection.Wishes3]: 'category.additional_details',
  [AnaSection.Wishes4]: 'category.additional_details',
  [AnaSection.Consents]: 'category.finalize',
  [AnaSection.FactoringAgreement]: 'screen.factoring_agreement.title',
  [AnaSection.Success]: 'screen.success.title',
}

const subTitles = (optins?: Optins, offerInsurance?: boolean) => ({
  [AnaSection.PersonalInformation]: 'subcategory.personal_data',
  [AnaSection.ContactDetails]: 'subcategory.contact_details',
  [AnaSection.Insurance]: 'subcategory.insurance_details',
  [AnaSection.Miscellaneous]: 'subcategory.miscellaneous',
  [AnaSection.Preconditions1]: 'subcategory.pre_existing_conditions',
  [AnaSection.Preconditions2]: 'subcategory.pre_existing_conditions',
  [AnaSection.Preconditions3]: 'subcategory.pre_existing_conditions',
  [AnaSection.AllergiesAndMedications]: 'category.anamnesis',
  [AnaSection.Wishes1]: 'subcategory.let_us_know_about_you',
  [AnaSection.Wishes2]: 'subcategory.let_us_know_about_you',
  [AnaSection.Wishes3]: 'subcategory.let_us_know_about_you',
  [AnaSection.Wishes4]: 'subcategory.let_us_know_about_you',
  [AnaSection.Consents]: (() => {
    if (!optins?.confidentialityAgreementAccepted || !optins?.marketingCommunicationConsentAccepted || offerInsurance) {
      return 'subcategory.consents_and_cancelation_policy'
    }

    return 'subcategory.only_cancelation_policy'
  })(),
  [AnaSection.FactoringAgreement]: 'subcategory.convenient_payment',
  [AnaSection.Success]: 'subcategory.success',
})

export type AnamnesisScreenRenderProps = (props: {
  goToNextStep: () => void
  send: (optins: Partial<Form>) => void
  onSubmit: (values: Partial<Form>) => void
  optins: Optins
  answers: Partial<Form>
  EndBarDataAttr: string
  offerInsurance?: boolean
  factoringAgreementMissing: boolean
  loading: boolean
}) => ReactNode

const AnamnesisScreen = ({
  children = PersonalInformationContent,
  EndBarContent,
}: {
  EndBarContent?: ReactNode
  children?: AnamnesisScreenRenderProps | ReactNode
}) => {
  const { answers, clinicReferenceId, factoringAgreementMissing, referenceId, loading } = useAnaContext()

  const {
    query: { mode },
  } = useRouter()

  const anaMode = AnaMode[String(mode).toUpperCase()]

  const { step, goToNextStep, indices, allDone } = useAnaNavigation({
    mode: anaMode,
  })

  const { openDialog, closeDialog } = useDialog()
  const { mutate: mutateAna } = useAnamnesisMutation()
  const { mutate: mutateOptins } = useOptinsMutation({ clinicReferenceId })
  const { mutate: mutateInsurance } = useSupplementaryInsuranceOffer()

  const { data: optinsData } = useOptins({ enabled: step === AnaSection.Consents })

  const optins = optinsData?.find((itm) => itm.clinicReferenceId === clinicReferenceId)?.optIns

  const search = useSearchParams()

  const id = search.get('referenceId')

  const dispatch = useAnaDispatch()

  const { initRenewOrEdit, initNew } = useInit({ mode: anaMode })

  const shouldLoadAna = id !== referenceId && mode !== AnaMode.NEW

  const { isLoading: anaLoading } = useAnamnesisById({
    enabled: shouldLoadAna,
    anamnesisReferenceId: id,
    onSuccess: initRenewOrEdit,
  })

  const shouldLoadPerson = id !== clinicReferenceId && mode === AnaMode.NEW

  const { loading: personLoading } = usePerson({
    skip: !shouldLoadPerson,
    onCompleted: (data) => {
      initNew({ clinicReferenceId: id, person: data.person })
    },
  })

  useEffect(() => {
    dispatch(setLoading(false))
  }, [])

  const onSubmit = (values: Partial<Form>) => {
    dispatch(setAnswers(values))
  }

  const send = (payload: Partial<Optins> & { insuranceConsentAccepted?: boolean }) => {
    openDialog(DialogNames.Signature, {
      patient: `${answers.firstName} ${answers.lastName}`,
      onSubmit: (signature: string) => {
        mutateAna(
          {
            signatureEncoded: stripBase(signature),
            formData: {
              ...answers,
              birthday: formatBirthday(answers.birthday || ''),
              holderBirthday: formatBirthday(answers.holderBirthday || ''),
              contactPersonBirthday: formatBirthday(answers.contactPersonBirthday || ''),
              insuranceCompanyId: answers.insuranceCompanyId || null,
              insuranceCompany: answers.insuranceCompanyId ? undefined : answers.insuranceCompany,
              dentalInsuranceCompanyId: answers.dentalInsuranceCompanyId || null,
              livesOnDifferentAddress: answers.livesOnDifferentAddress || false,
              dentalInsuranceCompany: answers.dentalInsuranceCompanyId ? undefined : answers.dentalInsuranceCompany,
            },
            clinicReferenceId,
          },
          {
            onSuccess: () => {
              closeDialog()

              const { insuranceConsentAccepted, ...optins } = payload
              const { language: languageCode } = answers

              mutateOptins(optins, {
                onSuccess: insuranceConsentAccepted
                  ? () =>
                      mutateInsurance(
                        { languageCode, consentGiven: insuranceConsentAccepted },
                        { onSettled: goToNextStep }
                      )
                  : goToNextStep,
              })
            },
          }
        )
      },
    })
  }

  const { data: toothFindingsSummary } = useToothFindingsSummary({
    enabled: step === AnaSection.Consents,
  })

  const { data: insured } = useInsuranceStatus({ enabled: step === AnaSection.Consents })

  const offerInsurance = answers.type === InsuranceType.SOCIAL_INSURANCE && !insured && toothFindingsSummary.length > 0

  return (
    <InternalTemplate Prefooter={EndBarContent || <EndBar data-attr={EndBarDataAttr} />}>
      {anaLoading || shouldLoadAna || shouldLoadPerson || personLoading ? null : (
        <S.Root>
          <>
            <StepperProgress
              finished={allDone}
              steps={Math.max(...Object.values(indices)) + 1}
              current={indices[step]}
            />
            <S.Title>
              <Translate>{titles[step]}</Translate>
            </S.Title>
            <SubTitle>
              <Translate>{subTitles(optins, offerInsurance)[step]}</Translate>
            </SubTitle>
          </>

          {typeof children === 'function'
            ? children({
                optins,
                goToNextStep,
                onSubmit,
                send,
                offerInsurance,
                answers,
                EndBarDataAttr,
                factoringAgreementMissing,
                loading,
              })
            : children}
        </S.Root>
      )}
    </InternalTemplate>
  )
}

export default AnamnesisScreen
