import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { CostPlanStatus } from 'src/@types/CostPlan'
import { setDefaultBookingContext, useBookingsDispatch } from 'src/components/providers/BookingsProvider'
import { usePersonId } from 'src/components/providers/PersonIdProvider'
import useClinicPractitioners, { ClinicPractitionerKind } from 'src/graphql/hooks/useClinicPractitioners'

type TabItem = {
  label: string
  path: string
  effect?: () => void
}

export const useTabs = () => {
  const { t } = useTranslation()
  const dispatch = useBookingsDispatch()
  const router = useRouter()

  const personReferenceId = usePersonId()

  const { data: inactivePractitioners } = useClinicPractitioners({
    options: {
      variables: { kind: ClinicPractitionerKind.Inactive },
      skip: !personReferenceId || !router.asPath.includes('practitioners'),
    },
  })

  const conditionalItem = (condition: boolean, item: TabItem) => {
    return condition ? [item] : []
  }

  return {
    practitioners: [
      {
        label: t('b2c.tabs.practitioners.favorite'),
        path: '/',
      },
      {
        label: t('b2c.tabs.practitioners.all'),
        path: '/all',
      },
      ...conditionalItem(inactivePractitioners?.data?.map((ip) => ip.practitioners).flat().length > 0, {
        label: t('b2c.tabs.practitioners.inactive'),
        path: '/inactive',
      }),
    ],
    appointments: [
      {
        label: t('b2c.appointments.tabs.upcoming'),
        path: '/',
        effect: () => {
          dispatch(setDefaultBookingContext())
        },
      },
      {
        label: t('b2c.appointments.tabs.past'),
        path: '/past',
        effect: () => {
          dispatch(setDefaultBookingContext())
        },
      },
      {
        label: t('b2c.appointments.tabs.cancelled'),
        path: '/cancelled',
        effect: () => {
          dispatch(setDefaultBookingContext())
        },
      },
    ],
    costplan: [
      { label: t(`b2c.costplan.status.${CostPlanStatus.ACCEPTED.toLowerCase()}`), path: '/' },
      { label: t('b2c.tabs.costplans.all'), path: '/all' },
    ],
    record: [
      {
        label: t('b2c.tabs.record.teeth'),
        path: '/',
      },
      {
        label: t('b2c.tabs.record.gums'),
        path: '/gums',
      },
      {
        label: t('b2c.tabs.record.imaging'),
        path: '/imaging',
      },
      {
        label: t('b2c.tabs.record.timeline'),
        path: '/timeline',
      },
    ],
  }
}
