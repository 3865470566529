import { Components, Theme } from '@mui/material'

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    input: ({ theme }) => ({
      fontSize: theme.typography['Lato Body 1'].fontSize,
      fontWeight: theme.typography['Lato Body 1'].fontWeight,
      letterSpacing: theme.typography['Lato Body 1'].letterSpacing,
      color: theme.palette.grey[800],
    }),
  },
}

export default MuiInputBase
