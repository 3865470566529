import React, { ReactNode } from 'react'

const PersonIdContext = React.createContext<string | null>(null)

const PersonIdProvider = ({ value, children }: { value: string | null; children: ReactNode | ReactNode[] }) => {
  return <PersonIdContext.Provider value={value}>{children}</PersonIdContext.Provider>
}

export const usePersonId = () => React.useContext(PersonIdContext) ?? null

export default PersonIdProvider
