import { styled } from '@mui/material/styles'
import TabsBase from '@mui/material/Tabs'

import { APPBAR_HEIGHT } from 'src/components/AppBar/AppBar.styles'

export const CONTENT_WIDTH = 174
export const TABS_HEIGHT = 6

export const Tabs = styled(TabsBase)(({ theme }) => ({
  maxWidth: theme.spacing(CONTENT_WIDTH),
  margin: 'auto',
  height: theme.spacing(TABS_HEIGHT),
}))

export const TabsContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(APPBAR_HEIGHT),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.primary[50]}`,
  backgroundColor: theme.palette.grey[0],
  width: '100%',
  zIndex: theme.zIndex.appBar,

  [theme.breakpoints.up('sm')]: {
    boxShadow: 'none',
    padding: theme.spacing(0, 2),
  },
}))

export const Content = styled('div')({})
