import { RecommendationSpeciality, RecommendationCategory } from '@dentalux/common'

import { Nullable } from './Nullable'

export enum IgnoredReason {
  AlreadyDone = 'ALREADY_DONE',
  NotSuitable = 'NOT_SUITABLE',
  InsufficientInformation = 'INSUFFICIENT_INFORMATION',
  Scared = 'SCARED',
  HighEffort = 'HIGH_EFFORT',
  HiddenCosts = 'HIDDEN_COSTS',
  Other = 'OTHER',
}

export enum RecommendationSpecialityAggregation {
  Dental = 'DENTAL',
  Other = 'OTHER',
}

export enum RecommendationFrequencyUnit {
  Hours = 'HOURS',
  Days = 'DAYS',
  Months = 'MONTHS',
  Years = 'YEARS',
  HalfDays = 'HALF_DAYS',
  Weeks = 'WEEKS',
  Decades = 'DECADES',
}

export enum SingularRecommendationFrequencyUnit {
  Hour = 'HOUR',
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
  HalfDay = 'HALF_DAY',
  Week = 'WEEK',
  Decade = 'DECADE',
}

export type RecommendationTreatmentBundle = {
  referenceId: string
  clinicReferenceId: string
  name: string
  translationKey: string
  iconUrl: string
  lastTimePersonHadThatTreatment: string
}

export type RecommendationFrequency = {
  unit: RecommendationFrequencyUnit
  count: number
}

export type RecommendationData = {
  referenceId: string
  titleTranslationKey: string
  shortDescriptionTranslationKey: string
  longDescriptionTranslationKey: string
  category: RecommendationCategory
  speciality: RecommendationSpeciality
  treatmentBundles: Array<RecommendationTreatmentBundle>
  ignored: boolean
  ignoredReason: Nullable<IgnoredReason>
  ignoredBy: string
  ignoredAt: string
  accomplishedAt: string
  accomplishedReason: string
  createdAt: string
  createdBy: string
  frequency: Nullable<RecommendationFrequency>
  costTranslationKey: string
  isCostPlanRelated: boolean
}

export type IgnoredRecommendationFields = {
  ignoredReason: IgnoredReason
  hadTreatmentAlreadyAt?: string
}
