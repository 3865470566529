import { AxiosError } from 'axios'

export enum ErrorCodes {
  TooManyVerificationCodesSent = 'HADES_206_TOO_MANY_VERIFICATION_CODES_SENT',
  RegistrationTokenExpired = 'HADES_112_HA_REGISTRATION_TOKEN_EXPIRED',
  PatientTokenDisabled = 'HADES_104_DISABLED_PATIENT_TOKEN',
  BookingSlotGone = 'BOOKING_SLOT_GONE',
  AdjacentBooking = 'ADJACENT_BOOKING',
  SlotsDontMatch = 'APPOINTMENTS_AND_TREATMENT_TIME_SLOTS_DONT_MATCH(409)',
  Fallback = 'HADES_01_FALLBACK',
  AdapterProcessError = 'ADAPTER_PROCESS_ERROR',
  AdapterCommError = 'ADAPTER_COMM_ERROR',
}

export const isAdapterErrror = (error: AxiosError) =>
  [ErrorCodes.AdapterProcessError, ErrorCodes.AdapterCommError].includes(error.response?.data?.code)
