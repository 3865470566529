import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { TeethFindingsSummary } from 'src/@types/Finding'
import { BaseImage, Image, ImageType } from 'src/@types/Imaging'
import { ToothFinding } from 'src/@types/Jaw'
import { ImagingResponse, PaginatedResponse } from 'src/@types/Pagination'
import { PatientHistoryEntry } from 'src/@types/PatientHistory'
import { PSISummary } from 'src/@types/PSI'
import { ThreeDJawResponse } from 'src/@types/ThreeDJaw'
import { ToothPosition } from 'src/@types/Tooth'
import { Visitation } from 'src/@types/Visitation'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MEDICAL_RECORD_URL,
})

addAuthInterceptors(http)

export const generate3dModel = async ({
  modelReferenceId,
  ...params
}: {
  modelReferenceId: string
  personReferenceId: string
}): Promise<ThreeDJawResponse> => {
  const { data } = await http.get(`/medical-records/jaw-findings/${modelReferenceId}/jaw3d`, {
    params,
  })

  return data
}

export const getToothFindingsSummary = async (
  personReferenceId: string
): Promise<PaginatedResponse<TeethFindingsSummary>> => {
  const { data } = await http.get('/medical-records/jaw-findings/summary', {
    params: { personReferenceId },
  })

  return data
}

export const getToothFindingsById = async (
  referenceId: string,
  personReferenceId: string
): Promise<{ model: { toothFindings: Partial<Record<ToothPosition, ToothFinding>> } }> => {
  const { data } = await http.get(`/medical-records/jaw-findings/${referenceId}`, { params: { personReferenceId } })

  return data
}

export const getPatientHistory = async (personReferenceId: string): Promise<PaginatedResponse<PatientHistoryEntry>> => {
  const { data } = await http.get(`/patient-history/v2`, {
    params: { personReferenceId },
  })

  return data
}

export const getPSISummary = async (personReferenceId: string): Promise<PaginatedResponse<PSISummary>> => {
  const { data } = await http.get(`/external/medical-records/dental-psi-diagnosis`, {
    params: { personReferenceId },
  })

  return data
}

export const getPSIById = async (
  referenceId: string,
  personReferenceId: string
): Promise<PaginatedResponse<{ model: PSISummary }>> => {
  const { data } = await http.get(`/external/medical-records/dental-psi-diagnosis/${referenceId}`, {
    params: { personReferenceId },
  })

  return data
}

export const getImagingSummary = async (
  personReferenceId: string,
  page: number,
  size: number
): Promise<ImagingResponse<Image>> => {
  const { data } = await http.get(`/external/medical-records/images?page=${page}&size=${size}`, {
    params: { personReferenceId },
  })

  return data
}

export const getImage = async (referenceId: string, type: ImageType): Promise<BaseImage> => {
  const { data } = await http.get(`/external/medical-records/images/${referenceId}`, {
    params: { type },
  })

  return data
}

export const getVisitation = async (appointmentReferenceId: string): Promise<Visitation> => {
  const { data } = await http.get<Visitation>(`/patient-history/v2/visitation/${appointmentReferenceId}`)

  return data
}
