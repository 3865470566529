import { Components, Theme } from '@mui/material'

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[800],
      minWidth: theme.spacing(4.5),
    }),
  },
}

export default MuiListItemIcon
