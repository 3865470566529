import React, { ReactNode } from 'react'

import * as S from './BaseAppBar.styles'

export type AppBarProps = {
  className?: string
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  leftContent?: ReactNode
  middleContent?: ReactNode
  rightContent?: ReactNode
}

const AppBar = ({ className, leftContent, middleContent, rightContent, position = 'sticky' }: AppBarProps) => {
  return (
    <S.AppBar className={className} position={position}>
      <S.Toolbar disableGutters>
        <S.ToolbarContent>
          <S.ToolbarItem direction="row" justifyContent="flex-start" spacing={2}>
            {leftContent}
          </S.ToolbarItem>

          {middleContent && (
            <S.ToolbarItem direction="row" justifyContent="center" spacing={2}>
              {middleContent}
            </S.ToolbarItem>
          )}

          <S.ToolbarItem direction="row" justifyContent="flex-end" spacing={2}>
            {rightContent}
          </S.ToolbarItem>
        </S.ToolbarContent>
      </S.Toolbar>
    </S.AppBar>
  )
}

export default AppBar
