import React, { ReactNode } from 'react'

import ExternalTemplate from '../ExternalTemplate'

export type ErrorTemplateProps = {
  children?: ReactNode | ReactNode[]
}

export const ErrorTemplate = ({ children }: ErrorTemplateProps) => {
  return (
    <ExternalTemplate href="/" bar={{ show: true, onlyLogo: true }}>
      {children}
    </ExternalTemplate>
  )
}
