export enum DataTestIds {
  ToothFindingsSummary = 'toothFindingsSummary',
  ResendButton = 'resendButton',
  FlagButton = 'flagButton',
  AppointmentCard = 'appointment-card',
  ShowJawModel = 'showJawModel',
  StrengthIndicator = 'strengthIndicator',
  RegistrationFirstName = 'firstName',
  RegistrationLastName = 'lastName',
  RegistrationDateOfBirth = 'dateOfBirth',
  MobilePhoneInput = 'mobilePhone',
  FeeAcknowledgementCheckbox = 'feeAcknowledgementCheckbox',
  RegistrationEmail = 'email',
  RegistrationPassword = 'password',
  RegistrationPI = 'patientInformationConsent',
  RegistrationTOS = 'termsOfServicePatientAccountConsent',
  SubmitButton = 'submit',
  TrustedDevice = 'trusted-device-',
  PSIRowLabelCell = 'psi-row-label-cell',
  PSIRow = 'psi-row',
  PSIRowCell = 'psi-row-cell',
  InvisibleProfileImageInput = 'invisible-profile-image-input',
  CountrySearch = 'countrySearch',
  OpeningHours = 'opening-hours',
  ClinicTooltip = 'clinic-tooltip',
  PractitionerCard = 'practitioner-card',
  PractitionerCardTitle = 'practitioner-card-title',
  PractitionerCardSubTitle = 'practitioner-card-subtitle',
  PractitionerCardDescription = 'practitioner-card-description',
  AccessData = 'access-data',
  AppointmentDetailedItem = 'appointment-detailed-item',
  DateSlot = 'appointments-picker-available-slot',
  AnamnesisCard = 'anamnesis-card',
  RecommendationCard = 'recommendation-card',
  DismissButton = 'dismiss-button',
  PractitionerPageHeader = 'practitioner-page-header',
  PractitionerPageDetails = 'practitioner-page-details',
  SpokenLanguage = 'spoken-language',
  PracticeInformation = 'practice-information',
  PracticePhoneNumber = 'practice-phone-number',
  PracticeLocation = 'practice-location',
  PractitionerOfferedTreatments = 'practitioner-offered-treatments',
  HeaderLogo = 'header-logo',
  DentalMedicineRecommendationsButton = 'dental-medicine-recommendations-button',
  HumanMedicineRecommendationsButton = 'human-medicine-recommendations-button',
  TrustedDeviceCheckbox = 'trusted-device-checkbox',
  DentalIQDashboardWidget = 'diq-dashboard-widget',
  DentalIQLearnMore = 'diq-learn-more',
  DentalIQPageHeader = 'diq-page-header',
  DentalIQPageTeeth = 'diq-page-teeth',
  DentalIQPageGums = 'diq-page-gums',
  DentalIQBookRecommendation = 'diq-book-recommendation',
  CostPlansBookRecommendation = 'costplan-book-recommendation',
  DentalIQBookRecommendationFromGums = 'diq-book-recommendation-from-gums',
  DentalIQBookRecommendationFromTeeth = 'diq-book-recommendation-from-teeth',
  ContinueBookingAfterGoingBackToTreatment = 'continue-booking-after-going-back-to-treatment',
  DashboardBookRecommendation = 'dashboard-book-recommendation',
  ConfirmBook = 'confirm-book',
  DashboardBook = 'dashboard-book',
  PractitionerBook = 'practitioner-book',
  AppointmentsFavBook = 'appointments-fav-book',
  DashboardBookRecommendationFromDetils = 'dashboard-book-recommendation-from-details',
  DentalIQGoToRecord = 'diq-go-to-record',
  TwoDJawModel = '2d-jaw-model',
  TwoDJawModelTeethList = '2d-jaw-model-teeth-list',
  TwoDGumsModel = '2d-gums-model',
  ImagingSection = 'imaging-section',
  XRayImage = 'x-ray-image',
  ProfilePictureAvatar = 'profile-picture-avatar',
  CloseDialogButton = 'close-dialog-button',
  BackButton = 'back-button',
}
