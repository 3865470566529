import * as ana from './ana'
import * as clinic from './clinic'
import * as core from './core'
import * as employee from './employee'
import * as hades from './hades'
import * as insurance from './insurance'
import * as invoice from './invoice'
import * as medicalRecords from './medicalRecords'
import * as monitoring from './monitoring'
import * as patient from './patient'
import * as patientScorpio from './patientScorpio'
import * as recommendation from './recommendation'

export default {
  patientScorpio,
  medicalRecords,
  monitoring,
  recommendation,
  hades,
  ana,
  invoice,
  insurance,
  employee,
  patient,
  clinic,
  core,
}
