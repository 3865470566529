import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { ClinicStatus } from 'src/@types/ClinicStatus'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MONITORING_URL,
})

addAuthInterceptors(http)

export const getClinicStatus = async ({
  clinicReferenceId,
}: {
  clinicReferenceId: string
}): Promise<ClinicStatus[]> => {
  const { data } = await http.get(`external/clinics-states`, {
    params: { clinicReferenceIds: clinicReferenceId },
  })

  return data
}
