import React, { FC } from 'react'

import dynamic from 'next/dynamic'

import { PersonalInformationFormProps } from '@dentalux/ui-library-core/cjs/custom/components/ana-toolbox'

import AnamnesisPage from 'pages/anamnesis/[referenceId]/[mode]'

const PersonalInformationForm = dynamic(() =>
  import('@dentalux/ui-library-core/cjs/custom/components/ana').then((mod) => mod.PersonalInformationForm)
) as FC<PersonalInformationFormProps>

export const PersonalInformationContent = ({ goToNextStep, onSubmit, answers, EndBarDataAttr, loading }) => {
  return (
    <PersonalInformationForm
      loading={loading}
      canBeFilledForDifferentPerson={false}
      portalAttr={EndBarDataAttr}
      onSubmit={(values) => {
        onSubmit(values)
        goToNextStep()
      }}
      initialValues={answers}
    />
  )
}

export const PersonalInformationScreen = () => <AnamnesisPage>{PersonalInformationContent}</AnamnesisPage>
