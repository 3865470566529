import React, { ExoticComponent } from 'react'

import dynamic from 'next/dynamic'

import { useDialog } from '../providers/DialogProvider'

const AccountDeletionConfirmationDialog = dynamic(() =>
  import('./AccountDeletionConfirmationDialog/AccountDeletionConfirmationDialog').then(
    (mod) => mod.AccountDeletionConfirmationDialog
  )
)

const AccountDeletionReasonDialog = dynamic(() =>
  import('./AccountDeletionReasonDialog/AccountDeletionReasonDialog').then((mod) => mod.AccountDeletionReasonDialog)
)

const AnaSourceDialog = dynamic(() => import('./AnaSourceDialog/AnaSourceDialog').then((mod) => mod.AnaSourceDialog))

const AppointmentSlotNotAvailableDialog = dynamic(() =>
  import('./AppointmentSlotNotAvailableDialog/AppointmentSlotNotAvailableDialog').then(
    (mod) => mod.AppointmentSlotNotAvailableDialog
  )
)

const AdjacentBookingDialog = dynamic(() =>
  import('./AdjacentBookingDialog/AdjacentBookingDialog').then((mod) => mod.AdjacentBookingDialog)
)

const BookingConfirmationDialog = dynamic(() =>
  import('./BookingConfirmationDialog/BookingConfirmationDialog').then((mod) => mod.BookingConfirmationDialog)
)

const CancelAppointmentDialog = dynamic(() =>
  import('./CancelAppointmentDialog/CancelAppointmentDialog').then((mod) => mod.CancelAppointmentDialog)
)

const CancellationConfirmationDialog = dynamic(() =>
  import('./CancellationConfirmationDialog/CancellationConfirmationDialog').then(
    (mod) => mod.CancellationConfirmationDialog
  )
)

const DiscardProfileImageChangesDialog = dynamic(() =>
  import('./DiscardProfileImageChangesDialog/DiscardProfileImageChangesDialog').then(
    (mod) => mod.DiscardProfileImageChangesDialog
  )
)

const FeeRescheduleDialog = dynamic(() =>
  import('./FeeRescheduleDialog/FeeRescheduleDialog').then((mod) => mod.FeeRescheduleDialog)
)

const IgnoreRecommendationDialog = dynamic(() =>
  import('./IgnoreRecommendationDialog/IgnoreRecommendationDialog').then((mod) => mod.IgnoreRecommendationDialog)
)

const ImmutableAppointmentDialog = dynamic(() =>
  import('./ImmutableAppointmentDialog/ImmutableAppointmentDialog').then((mod) => mod.ImmutableAppointmentDialog)
)

const InvoicePaymentDetailsDialog = dynamic(() =>
  import('./InvoicePaymentDetailsDialog/InvoicePaymentDetailsDialog').then((mod) => mod.InvoicePaymentDetailsDialog)
)

const RemoveProfileImageDialog = dynamic(() =>
  import('./RemoveProfileImageDialog/RemoveProfileImageDialog').then((mod) => mod.RemoveProfileImageDialog)
)

const ResetPhoneNumberDialog = dynamic(() =>
  import('./ResetPhoneNumberDialog/ResetPhoneNumberDialog').then((mod) => mod.ResetPhoneNumberDialog)
)

const ShortTermCancelationDialog = dynamic(() =>
  import('./ShortTermCancelationDialog/ShortTermCancelationDialog').then((mod) => mod.ShortTermCancelationDialog)
)

const ShortTermRescheduleDialog = dynamic(() =>
  import('./ShortTermRescheduleDialog/ShortTermRescheduleDialog').then((mod) => mod.ShortTermRescheduleDialog)
)

const SignatureDialog = dynamic(() => import('./SignatureDialog/SignatureDialog').then((mod) => mod.SignatureDialog))

const SupplementaryInsuranceFailureDialog = dynamic(() =>
  import('./SupplementaryInsuranceFailureDialog/SupplementaryInsuranceFailureDialog').then(
    (mod) => mod.SupplementaryInsuranceFailureDialog
  )
)

const SupplementaryInsuranceSuccessDialog = dynamic(() =>
  import('./SupplementaryInsuranceSuccessDialog/SupplementaryInsuranceSuccessDialog').then(
    (mod) => mod.SupplementaryInsuranceSuccessDialog
  )
)

const BiometricLoginDialog = dynamic(() =>
  import('./BiometricLoginDialog/BiometricLoginDialog').then((mod) => mod.BiometricLoginDialog)
)

const RemoveTrustedDeviceDialog = dynamic(() =>
  import('./RemoveTrustedDeviceDialog/RemoveTrustedDeviceDialog').then((mod) => mod.RemoveTrustedDeviceDialog)
)

export enum DialogNames {
  CancelAppointment = 'CANCEL_APPOINTMENT',
  RemoveProfileImage = 'REMOVE_PROFILE_IMAGE',
  DiscardProfilePictureChanges = 'DISCARD_PROFILE_PICTURE_CHANGES',
  ImmutableAppointment = 'IMMUTABLE_APPOINTMENT',
  ShortTermCancelation = 'SHORT_TERM_CANCELATION',
  AppointmentSlotNotAvailable = 'APPOINTMENT_SLOT_NOT_AVAILABLE',
  AdjacentBooking = 'ADJACENT_BOOKING',
  CancellationConfirmation = 'CANCELLATION_CONFIRMATION',
  ShortTermReschedule = 'SHORT_TERM_RESCHEDULE',
  FeeReschedule = 'FEE_RESCHEDULE',
  BookingConfirmation = 'BOOKING_CONFIRMATION',
  ResetPhoneNumber = 'RESET_PHONE_NUMBER',
  IgnoreRecommendation = 'IGNORE_RECOMMENDATION',
  Signature = 'SIGNATURE',
  AnaSourceDialog = 'ANA_SOURCE_DIALOG',
  SupplementaryInsuranceSuccess = 'SUPPLEMENTARY_SUCCESS',
  SupplementaryInsuranceFailure = 'SUPPLEMENTARY_FAILURE',
  Onboarding = 'ONBOARDING',
  AccountDeletionConfirmation = 'ACCOUNT_DELETION_CONFIRMATION',
  AccountDeletionReason = 'ACCOUNT_DELETION_REASON',
  InvoicePaymentDetails = 'INVOICE_PAYMENT_DETAILS',
  BiometricLogin = 'BIOMETRIC_LOGIN',
  RemoveTrustedDevice = 'REMOVE_TRUSTED_DEVICE',
}

const DIALOG_COMPONENTS = {
  [DialogNames.CancelAppointment]: CancelAppointmentDialog,
  [DialogNames.AppointmentSlotNotAvailable]: AppointmentSlotNotAvailableDialog,
  [DialogNames.AdjacentBooking]: AdjacentBookingDialog,
  [DialogNames.ImmutableAppointment]: ImmutableAppointmentDialog,
  [DialogNames.ShortTermCancelation]: ShortTermCancelationDialog,
  [DialogNames.CancellationConfirmation]: CancellationConfirmationDialog,
  [DialogNames.ShortTermReschedule]: ShortTermRescheduleDialog,
  [DialogNames.RemoveProfileImage]: RemoveProfileImageDialog,
  [DialogNames.DiscardProfilePictureChanges]: DiscardProfileImageChangesDialog,
  [DialogNames.FeeReschedule]: FeeRescheduleDialog,
  [DialogNames.BookingConfirmation]: BookingConfirmationDialog,
  [DialogNames.ResetPhoneNumber]: ResetPhoneNumberDialog,
  [DialogNames.IgnoreRecommendation]: IgnoreRecommendationDialog,
  [DialogNames.Signature]: SignatureDialog,
  [DialogNames.AnaSourceDialog]: AnaSourceDialog,
  [DialogNames.SupplementaryInsuranceSuccess]: SupplementaryInsuranceSuccessDialog,
  [DialogNames.SupplementaryInsuranceFailure]: SupplementaryInsuranceFailureDialog,
  [DialogNames.AccountDeletionConfirmation]: AccountDeletionConfirmationDialog,
  [DialogNames.AccountDeletionReason]: AccountDeletionReasonDialog,
  [DialogNames.InvoicePaymentDetails]: InvoicePaymentDetailsDialog,
  [DialogNames.BiometricLogin]: BiometricLoginDialog,
  [DialogNames.RemoveTrustedDevice]: RemoveTrustedDeviceDialog,
}

const DialogRoot = () => {
  const { dialogType, dialogProps } = useDialog()

  if (!dialogType) return null

  const DialogComponent = DIALOG_COMPONENTS[dialogType] as ExoticComponent

  return <DialogComponent {...dialogProps} />
}

export default DialogRoot
