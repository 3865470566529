import { usePersonRecommendation } from './api/usePersonRecommendation'

export const useCostPlanEvaluation = ({ enabled = true }: { enabled?: boolean }) => {
  const { data: recommendation } = usePersonRecommendation({
    enabled,
    perPage: 1,
    isCostPlanBased: true,
  })

  return {
    costPlanRecommendation: recommendation?.content[0] || undefined,
  }
}
