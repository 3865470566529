import { styled } from '@mui/material/styles'

import { APPBAR_HEIGHT } from 'src/components/AppBar/AppBar.styles'

import { CONTENT_WIDTH } from '../TabContentTemplate/TabContentTemplate.styles'

export const INTERNAL_TEMPLATE_VERTICAL_PADDING = 3

export const LogoWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.primary[800],
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

export const ContentContainer = styled('main', {
  shouldForwardProp: (prop) => prop !== 'withTabs' && prop !== 'fullWidth',
})<{ withTabs: boolean; fullWidth: boolean }>(({ theme, withTabs, fullWidth }) => ({
  flexGrow: 1,
  margin: 'auto',
  maxWidth: fullWidth ? '100%' : theme.spacing(CONTENT_WIDTH),
  padding: fullWidth ? 0 : theme.spacing(INTERNAL_TEMPLATE_VERTICAL_PADDING, 2),
  background: 'transparent',
  minHeight: `calc(100vh - ${theme.spacing(
    APPBAR_HEIGHT + (withTabs ? INTERNAL_TEMPLATE_VERTICAL_PADDING + INTERNAL_TEMPLATE_VERTICAL_PADDING : 0)
  )})`,
  overflow: 'clip',

  [theme.breakpoints.up('sm')]: {
    padding: fullWidth ? 0 : theme.spacing(INTERNAL_TEMPLATE_VERTICAL_PADDING),
  },
}))
