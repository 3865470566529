import React from 'react'

import BaseListItemIcon from '@mui/material/ListItemIcon'
import BaseListItemText from '@mui/material/ListItemText'
import BaseMenuItem from '@mui/material/MenuItem'

import { Language } from 'src/@types/Language'

import { languageCodeToCountryCodeFallback } from '../Languages/Languages.helpers'

import * as S from './Langs.styles'

const languages = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'de',
    label: 'Deutsch',
  },
]

const Langs = ({
  currentLanguage,
  changeLanguage,
}: {
  currentLanguage: string
  changeLanguage: (language: string) => void
}) => (
  <>
    {languages.map((language) => (
      <BaseMenuItem
        key={language.code}
        onClick={() => changeLanguage(language.code)}
        selected={language.code === currentLanguage}
      >
        <BaseListItemIcon>
          <S.Image
            src={`/flags/1x1/${languageCodeToCountryCodeFallback.get(language.code as Language) || language.code}.svg`}
            alt={language.code}
          />
        </BaseListItemIcon>

        <BaseListItemText primary={language.label} primaryTypographyProps={{ variant: 'Lato Emphasized 2' }} />
      </BaseMenuItem>
    ))}
  </>
)

export default Langs
