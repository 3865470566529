import { Components, Theme } from '@mui/material'

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'initial',
      color: theme.palette.secondary[800],
      ...theme.typography['Lato Emphasized 2'],

      '&:not(:first-of-type)': {
        marginLeft: 0,

        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(2),
        },
      },

      '&:not(:last-of-type)': {
        marginRight: 0,

        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(2),
        },
      },

      [theme.breakpoints.up('md')]: {
        ...theme.typography['Lato Emphasized 1'],
      },
    }),

    textColorSecondary: ({ theme }) => ({
      color: theme.palette.grey[800],

      '&.Mui-selected': {
        color: theme.palette.secondary[800],
      },
    }),

    iconWrapper: ({ theme }) => ({
      marginRight: theme.spacing(1.5),
    }),
  },
}

export default MuiTab
