import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

import { useBaseProfileImage } from './useBaseProfileImage'

export const useProfileImage = ({ enabled = true }: { enabled?: boolean }) => {
  const personReferenceId = usePersonId()

  const { data: baseProfileImageData } = useBaseProfileImage({})

  const { data, isLoading } = useQuery(
    ['profile-image', personReferenceId],
    () => api.patient.getManipulatedProfileImage({ personReferenceId }),
    {
      enabled: Boolean(personReferenceId) && enabled,
      cacheTime: Number.POSITIVE_INFINITY,
      refetchOnWindowFocus: false,
    }
  )

  return {
    src: data?.base64String ? `data:${baseProfileImageData?.fileContentType};base64,${data.base64String}` : undefined,
    isLoading,
  }
}
