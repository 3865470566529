import React from 'react'

import { useCredentials } from '@dentalux/security'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

import { ErrorBoundary, ErrorBoundaryLevel } from 'src/components/ErrorBoundary/ErrorBoundary'
import { AnaProvider } from 'src/components/providers/AnaProvider'
import ApolloProvider from 'src/components/providers/ApolloProvider'
import { AuthorizedProvider } from 'src/components/providers/AuthorizedProvider'
import { BookingsProvider } from 'src/components/providers/BookingsProvider'
import { DialogProvider } from 'src/components/providers/DialogProvider'
import { LogoutKindProvider } from 'src/components/providers/LogoutKindProvider'
import PersonIdProvider from 'src/components/providers/PersonIdProvider'
import ReactQueryProvider from 'src/components/providers/ReactQueryProvider'
import { RecommendationProvider } from 'src/components/providers/RecommendationProvider'
import { SnackbarProvider } from 'src/components/providers/SnackbarProvider'
import { UnauthorizedProvider } from 'src/components/providers/UnauthorizedProvider'
import useDateFnsConfig from 'src/hooks/useDateFnsConfig'
import { ErrorTemplate } from 'src/templates/ErrorTemplate/ErrorTemplate'
import theme from 'src/theme'

const AllProviders = ({ children }) => {
  const { authentication } = useCredentials()

  const config = useDateFnsConfig()

  const personReferenceId = authentication?.personReferenceId

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={config.locale}>
        <AnaProvider>
          <BookingsProvider>
            <RecommendationProvider>
              <ReactQueryProvider>
                <ApolloProvider>
                  <UnauthorizedProvider>
                    <CssBaseline />
                    <ErrorBoundary dashboardButton Wrapper={ErrorTemplate} level={ErrorBoundaryLevel.Generic}>
                      <PersonIdProvider value={personReferenceId}>
                        <LogoutKindProvider>
                          <AuthorizedProvider>
                            <DialogProvider>
                              <SnackbarProvider>{children}</SnackbarProvider>
                            </DialogProvider>
                          </AuthorizedProvider>
                        </LogoutKindProvider>
                      </PersonIdProvider>
                    </ErrorBoundary>
                  </UnauthorizedProvider>
                </ApolloProvider>
              </ReactQueryProvider>
            </RecommendationProvider>
          </BookingsProvider>
        </AnaProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default AllProviders
