import { ClinicPractitioner } from './ClinicPractitioner'
import { Diagnosis } from './Diagnosis'
import { Disease } from './Disease'
import { LabData } from './LabData'
import { Medication } from './Medication'
import { Nullable } from './Nullable'
import { Patient } from './Patient'

export type PersonSummary = {
  hasPatientHistory: boolean
  hasJawFindings: boolean
  hasPsiDiagnosis: boolean
  hasImages: boolean
}

export enum Gender {
  Diverse = 'DIVERSE',
  Female = 'FEMALE',
  Male = 'MALE',
}

export type Person = {
  referenceId: string
  firstName: string
  lastName: string
  email: Nullable<string>
  phoneNumber: Nullable<string>
  birthday: string
  gender: Gender
  isDeleted: boolean
  summary: PersonSummary
  patients: Array<Patient>
  medications: Array<Medication>
  diagnoses: Array<Diagnosis>
  labData: Array<LabData>
  diseases: Array<Disease>
  allergies: Array<Disease>
  clinicPractitioners: Array<ClinicPractitioner>
}
