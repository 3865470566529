import React, { ReactNode } from 'react'

import { decodeToken, useCredentials, useSetCredentials } from '@dentalux/security'

import { isPast } from 'date-fns'

import * as S from './Menu.styles'

export type MenuProps = {
  trigger: ReactNode
  children: ReactNode | ReactNode[]
  justifyContent?: 'left' | 'right' | 'center'
}

const Menu = ({ trigger, children, justifyContent = 'left' }: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { token } = useCredentials()
  const setCredentials = useSetCredentials()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (token && isPast(decodeToken(token).exp * 1000)) {
      setCredentials(null, 'local')
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <S.Trigger onClick={handleClick}>{trigger}</S.Trigger>

      <S.Menu open={open} justifyContent={justifyContent} onClose={handleClose} onClick={handleClose}>
        {children}
      </S.Menu>
    </>
  )
}

export default Menu
