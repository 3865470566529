import { useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { Language, useCredentials, useSetPreferredLang } from '@dentalux/security'

import { setPreferredLanguageCode, useUnauthorizedDispatch } from 'src/components/providers/UnauthorizedProvider'

const useTranslationSwitch = ({
  syncLang = false,
  onSwitchLang,
}: {
  syncLang?: boolean
  onSwitchLang?: (lang: string) => void
}) => {
  const router = useRouter()

  const { pathname, asPath, query } = router

  const { authentication } = useCredentials()

  const { setPreferredLang } = useSetPreferredLang()

  const unauthorizedDispatch = useUnauthorizedDispatch()

  const { i18n } = useTranslation()

  const setActiveLang = (lang?: Language) => {
    unauthorizedDispatch(setPreferredLanguageCode(lang))
    onSwitchLang && onSwitchLang(lang)
    router.push({ pathname, query }, asPath, { locale: lang })
  }

  const preferredLanguageCode = authentication && authentication.preferredLanguageCode

  useEffect(() => {
    if (syncLang && preferredLanguageCode && preferredLanguageCode !== i18n.language && i18n.language !== 'cimode') {
      setActiveLang(authentication.preferredLanguageCode)
    }
  }, [preferredLanguageCode])

  return {
    setActiveLang,
    setPreferredLang,
    currentLanguage: i18n.language,
  }
}

export default useTranslationSwitch
