import { useEffect, useState } from 'react'

export const useOnScreen = (ref, element, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      { rootMargin }
    )

    if (ref.current && element) {
      observer.observe(ref.current)
    }

    return () => {
      if (observer && ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [element])

  return isIntersecting
}
