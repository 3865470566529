import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ProgressCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fill="none" sx={{ width: 18, height: 18 }}>
      <circle id="Ellipse 25" cx="9" cy="9" r="9" fill={props.fill || colors.aqua[800]} />
      <path
        id="Vector"
        d="M13.4557 6.00023C13.3184 6.0044 13.188 6.06282 13.0923 6.16312L7.6509 11.7012L5.91008 9.92945C5.86137 9.87781 5.80302 9.83659 5.73846 9.80819C5.67389 9.77978 5.60441 9.76478 5.53408 9.76405C5.46375 9.76332 5.39398 9.77689 5.32886 9.80394C5.26375 9.831 5.20459 9.87101 5.15485 9.92163C5.10512 9.97225 5.06581 10.0325 5.03922 10.0987C5.01264 10.165 4.99931 10.236 5.00003 10.3076C5.00074 10.3792 5.01548 10.4499 5.04339 10.5156C5.07129 10.5813 5.1118 10.6407 5.16254 10.6903L7.27713 12.8425C7.37628 12.9433 7.51072 13 7.6509 13C7.79109 13 7.92553 12.9433 8.02467 12.8425L13.8398 6.92395C13.9162 6.84838 13.9684 6.75108 13.9895 6.6448C14.0106 6.53853 13.9997 6.42823 13.9582 6.32838C13.9167 6.22853 13.8465 6.14377 13.7568 6.08521C13.6672 6.02666 13.5622 5.99704 13.4557 6.00023Z"
        fill={colors.grey[0]}
      />
    </SvgIcon>
  )
}

export default ProgressCheck
