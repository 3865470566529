import { Components, Theme } from '@mui/material'

const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[200],
    }),
  },
}

export default MuiFilledInput
