import { Nullable } from './Nullable'

export enum InvoiceStatus {
  Open = 'OPEN',
  Outdated = 'OUTDATED',
  Paid = 'PAID',
}

export type Invoice = {
  id: string
  paymentDueDate: string
  paymentReceivedDate: string
  status: Nullable<InvoiceStatus>
  totalAmountToBePaid: string
  amountRemainingToBePaid: string
  amountAlreadyPaid: string
  invoicePaymentDetailsResult: {
    recipientName: string
    bankName: string
    iban: string
    bic: string
    paymentTitle: string
  }
}
