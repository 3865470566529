import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'
import { Gender } from 'src/@types/Person'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PATIENT_URL,
})

addAuthInterceptors(http)

export type ImageManipulationInfo = {
  zoom: number
  rotation: number
  cropXAxis: number
  cropYAxis: number
}

export interface ImageManipulation extends ImageManipulationInfo {
  imageBase64String: string
  profileImageReferenceId: string
}

export const getBaseProfileImage = async ({
  personReferenceId,
}: {
  personReferenceId: string
}): Promise<{
  referenceId: string
  fileContentType: string
  fileName: string
  baseImageUrlDownloadUrl: string
  manipulationInfos: ImageManipulationInfo
}> => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/profile-images`)

  return data
}

export const getBaseProfileImageFile = async (url: string): Promise<Blob> => {
  const { data } = await http.get(url.replace('/api', ''), {
    responseType: 'blob',
  })

  return data
}

export const getManipulatedProfileImage = async ({ personReferenceId }: { personReferenceId: string }) => {
  const { data } = await http.get(`/external/persons/${personReferenceId}/profile-images/manipulated`)

  return data
}

export const postProfileImage = async ({
  personReferenceId,
  formData,
}: {
  personReferenceId: string
  formData: FormData
}): Promise<{ referenceId: string; manipulationInfos: ImageManipulationInfo }> => {
  const { data } = await http.post(`/external/persons/${personReferenceId}/profile-images`, formData)

  return data
}

export const deleteProfileImage = async ({
  personReferenceId,
  profileImageReferenceId,
}: {
  personReferenceId: string
  profileImageReferenceId: string
}) => {
  const { data } = await http.delete(`/external/persons/${personReferenceId}/profile-images/${profileImageReferenceId}`)

  return data
}

export const postManipulateProfileImage = async ({
  personReferenceId,
  profileImageReferenceId,
  formData,
}: {
  personReferenceId: string
  profileImageReferenceId: string
  formData: FormData
}): Promise<boolean> => {
  const { data } = await http.patch(
    `/external/persons/${personReferenceId}/profile-images/${profileImageReferenceId}`,
    formData
  )

  return data
}

export const updatePersonalData = async ({
  personReferenceId,
  ...body
}: {
  personReferenceId: string
  firstName?: string
  lastName?: string
  gender?: Gender
}): Promise<unknown> => {
  const { data } = await http.patch(`/persons/${personReferenceId}/personal-data`, body)

  return data
}

export const postFavoritePractitioner = async ({
  personReferenceId,
  practitionerReferenceId,
}: {
  personReferenceId: string
  practitionerReferenceId: string
}): Promise<boolean> => {
  const { data } = await http.post(
    `/persons/${personReferenceId}/favorite-practitioners?practitionerReferenceId=${practitionerReferenceId}`
  )

  return data
}

export const deleteFavoritePractitioner = async ({
  personReferenceId,
  practitionerReferenceId,
}: {
  personReferenceId: string
  practitionerReferenceId: string
}): Promise<boolean> => {
  const { data } = await http.delete(`/persons/${personReferenceId}/favorite-practitioners`, {
    params: {
      practitionerReferenceId,
    },
  })

  return data
}
