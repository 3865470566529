import { useMutation, useQueryClient } from 'react-query'

import { Form } from '@dentalux/ui-library-core/cjs/custom/components/ana-toolbox'

import api from 'api'
import { usePersonId } from 'src/components/providers/PersonIdProvider'

export const useAnamnesisMutation = () => {
  const client = useQueryClient()
  const personReferenceId = usePersonId()

  return useMutation(
    ({
      formData,
      signatureEncoded,
      clinicReferenceId,
    }: {
      formData: Partial<Form>
      signatureEncoded: string
      clinicReferenceId: string
    }) => api.ana.postAnamnesis({ personReferenceId, formData, signatureEncoded, clinicReferenceId }),
    {
      onSuccess: () => {
        client.invalidateQueries(['anamnesis_listing', personReferenceId])
        client.invalidateQueries(['optins', personReferenceId])
      },
    }
  )
}
