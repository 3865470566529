import { toolbarClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

import BaseAppBar from '../BaseAppBar/BaseAppBar'
import D21Logo from '../icons/D21Logo'

export const APPBAR_HEIGHT = 8

export const Logo = styled(D21Logo)(() => ({
  cursor: 'pointer',
  width: 'auto',
}))

export const AppBar = styled(BaseAppBar)(({ theme }) => ({
  height: theme.spacing(APPBAR_HEIGHT),
  zIndex: theme.zIndex.drawer + 1,
  '-webkit-transform': 'translateZ(0)',

  [`.${toolbarClasses.root}`]: {
    minHeight: theme.spacing(APPBAR_HEIGHT),
  },
}))
