import { Components, Theme } from '@mui/material'

const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    variant: 'Lato Link 1',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary[800],
    }),
  },
}

export default MuiLink
