import { useIdleTimer as useReactIdleTimer } from 'react-idle-timer'

import { useLogout, LogoutKind, useSetCredentials } from '@dentalux/security'

import { LocalStorage } from 'src/@types/LocalStorage'
import { reset, useAuthorizedDispatch } from 'src/components/providers/AuthorizedProvider'
import { useLogoutState } from 'src/components/providers/LogoutKindProvider'

interface IdleTimerType {
  timeout?: number
  promptBeforeIdle?: number
  onPrompt?: () => void
}

const timeout = Number(process.env.NEXT_PUBLIC_IDLE_TIMEOUT_IN_MS)
const promptBeforeIdle = Number(process.env.NEXT_PUBLIC_IDLE_PROMPT_TIMEOUT_IN_MS)

const defaultTimeout = timeout || 1000 * 60 * 5
const defaultPromptBeforeIdle = promptBeforeIdle || 1000 * 60 * 4

export const useIdleTimer = ({
  timeout = defaultTimeout,
  promptBeforeIdle = defaultPromptBeforeIdle,
  ...rest
}: IdleTimerType) => {
  const { setState } = useLogoutState()
  const logout = useLogout({ kind: LogoutKind.AUTO })
  const dispatch = useAuthorizedDispatch()
  const setCredentials = useSetCredentials()

  return useReactIdleTimer({
    timeout,
    promptBeforeIdle,
    onIdle: () => {
      localStorage.removeItem(LocalStorage.RedirectUrl)
      logout().then(() => {
        setCredentials(null, 'local')
        dispatch(reset())
        setState(LogoutKind.AUTO)
      })
    },
    ...rest,
  })
}
