import { backdropClasses } from '@mui/material'
import MuiDialog, { dialogClasses } from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'

import { APPBAR_HEIGHT } from '../AppBar/AppBar.styles'

export const Trigger = styled('div')({
  display: 'inherit',
  cursor: 'pointer',
})

export const Menu = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== 'justifyContent',
})<{ justifyContent: 'left' | 'right' | 'center' }>(({ theme, justifyContent }) => ({
  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'transparent',
  },

  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 286,
    margin: theme.spacing(APPBAR_HEIGHT, 2, 0, 2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[3],
  },

  [`& .${dialogClasses.container}`]: {
    alignItems: `flex-start`,
    justifyContent: justifyContent,
  },
}))
