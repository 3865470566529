import { addAuthInterceptors } from '@dentalux/security'

import axios from 'axios'

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_INSURANCE_URL,
})

addAuthInterceptors(http)

type InsuranceCompany = {
  city: string
  companyName: string
  country: string
  createdAt: string
  email: string
  insurerName: string
  modifiedAt: string
  postCode: string
  referenceId: string
  street: string
  telephoneNumbers: Array<string>
}

export const getInsuranceCompanies = async (): Promise<InsuranceCompany[]> => {
  const { data } = await http.get('/insurance-companies')

  return data
}

export const getInsuranceStatus = async ({
  partner = 'privadent',
  personReferenceId,
}: {
  partner?: string
  personReferenceId: string
}): Promise<boolean> => {
  const { data } = await http.get(`/v1/external/insurance-partners/${partner}/persons/${personReferenceId}/insured`)

  return data
}

export const getQuotableStatus = async ({
  partner = 'privadent',
  personReferenceId,
}: {
  partner?: string
  personReferenceId: string
}): Promise<boolean> => {
  const { data } = await http.get(`/v1/external/insurance-partners/${partner}/persons/${personReferenceId}/quotable`)

  return data
}

export const postSupplementaryOfferRequest = async ({
  languageCode = 'de',
  consentGiven,
  personReferenceId,
}: {
  languageCode?: string
  consentGiven: boolean
  personReferenceId: string
}) => {
  const { data } = await http.post(`/v1/external/insurance-partners/privadent/persons/${personReferenceId}`, {
    consentGiven,
    languageCode,
  })

  return data
}
