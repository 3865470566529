import { InvoiceStatus } from 'src/@types/Invoice'

import { useInvoices } from './api/useInvoices'

export const useInvoiceEvaluation = ({ enabled }: { enabled?: boolean }) => {
  const { data } = useInvoices({ enabled })

  const open = data?.filter((invoice) => invoice.status === InvoiceStatus.Open)
  const outdated = data?.filter((invoice) => invoice.status === InvoiceStatus.Outdated)

  return {
    all: data || [],
    open: open || [],
    outdated: outdated || [],
  }
}
