import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Star = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" sx={{ width: 16, height: 16 }}>
      <path
        d="M8 12.0608L11.3127 14.0601C11.8353 14.3754 12.48 13.9068 12.3413 13.3128L11.462 9.54411L14.3893 7.00811C14.8507 6.60877 14.604 5.85077 13.996 5.79944L10.1427 5.47277L8.63533 1.91611C8.39733 1.35544 7.60267 1.35544 7.36467 1.91611L5.85733 5.47277L2.004 5.79944C1.396 5.85077 1.14933 6.60877 1.61067 7.00811L4.538 9.54411L3.65867 13.3128C3.52 13.9068 4.16467 14.3754 4.68733 14.0601L8 12.0608Z"
        fill={colors.warning[300]}
      />
    </SvgIcon>
  )
}

export default Star
