import React from 'react'

import { useRouter } from 'next/router'

import MenuIcon from '@mui/icons-material/Menu'
import { ListItemIcon, ListItemText, MenuList, MenuItem, Box } from '@mui/material'
import Divider from '@mui/material/Divider'

import { useMenu } from 'src/hooks/useMenu'

import Menu from '../Menu'

import * as S from './SideBarMenu.styles'

const SideBarMenu = () => {
  const { asPath, ...router } = useRouter()

  const items = useMenu()

  return (
    <Menu trigger={<MenuIcon />}>
      <MenuList>
        {items.map(({ label, children }, index) => (
          <Box key={`${label}=item-menu-list`}>
            <S.Label>{label}</S.Label>

            {children.map((item) => (
              <MenuItem
                key={item.label}
                selected={asPath.split('/')[1] === item.href?.substring(1)}
                onClick={() => router.push(item.href).then(() => item?.effect?.())}
              >
                <ListItemIcon>{item.Icon}</ListItemIcon>
                <ListItemText primaryTypographyProps={{ variant: 'Lato Emphasized 2' }}>
                  {item.content || item.label}
                </ListItemText>
              </MenuItem>
            ))}

            {index !== items.length - 1 && <Divider key={label} />}
          </Box>
        ))}
      </MenuList>
    </Menu>
  )
}

export default SideBarMenu
