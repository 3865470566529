import { LinearProgress, Stack } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  boxShadow: 'initial',
  color: theme.palette.common.white,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 4),
  },
}))

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: 64,
  justifyContent: 'space-betwean',
  padding: 0,

  [theme.breakpoints.up('sm')]: {
    minHeight: 64,
  },
}))

export const ToolbarContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
}))

export const ToolbarItem = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
}))

export const ProgressBar = styled(LinearProgress)(() => ({
  position: 'absolute',
  top: 64,
  width: '100%',
  height: 2,
}))
