import { useQuery } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useOptins = ({ enabled = true }: { enabled?: boolean }) => {
  const personReferenceId = usePersonId()

  return useQuery(['optins', personReferenceId], () => api.ana.getOptins({ personReferenceId }), {
    enabled: enabled && Boolean(personReferenceId),
  })
}
