import React, { createContext, useReducer, useContext } from 'react'

import { useLocalStorage } from '@dentalux/common'
import { PainDetailsData } from '@dentalux/ui-library-core/cjs/custom/components/pain-details-form/types'

import { AppointmentBundle } from 'src/@types/AppointmentBundle'
import { AdditionalAppointmentHints, vitaminDUpsellInformation } from 'src/@types/Booking'
import { LocalStorage } from 'src/@types/LocalStorage'
import { MarketingParams } from 'src/@types/Marketing'
import { Nullable } from 'src/@types/Nullable'
import { BookingScreenType } from 'src/screens/Appointments/Management/types'

export type BookingIds = {
  clinicReferenceId?: Nullable<string>
  appointmentBundleReferenceId?: Nullable<string>
  practitionerReferenceId?: Nullable<string>
  practitionerReferenceIds?: Array<string>
  treatmentBundleReferenceId?: Nullable<string>
  upsellTreatmentBundleReferenceId?: Nullable<string>
}

type Slot = {
  start?: string
  duration?: number
}

export type Treatment = {
  name: string
  referenceId: string
  clinicReferenceId: string
}

export type Recommendation = {
  referenceId: string
  treatments: Treatment[]
}

export type ManipulatedAppointment = {
  practitioner: string
  start: string
}

export enum ActionTypes {
  setBookingIds = 'setBookingIds',
  setManipulatedAppointment = 'setManipulatedAppointment',
  setRecommendations = 'setRecommendations',
  setUpsellReferenceId = 'setUpsellReferenceId',
  setDefaultBookingsContext = 'setDefaultBookingsContext',
  setRecommendationDefaultBookingsContext = 'setRecommendationDefaultBookingsContext',
  setSlot = 'setSlot',
  setAppointmentBundle = 'setAppointmentBundle',
  setBookingScreenType = 'setBookingScreenType',
  setTreatmentTitle = 'setTreatmentTitle',
  setPainDetails = 'setPainDetails',
  setMarketingParams = 'setMarketingParams',
  setUpsellInformation = 'setUpsellInformation',
}

type SetBookingsIdsAction = { type: ActionTypes.setBookingIds; payload: BookingIds }
type SetRescheduledAppointmentAction = { type: ActionTypes.setManipulatedAppointment; payload: ManipulatedAppointment }
type SetDefaultBookingsContextAction = { type: ActionTypes.setDefaultBookingsContext }
type SetRecommendationDefaultBookingsContextAction = { type: ActionTypes.setRecommendationDefaultBookingsContext }
type SetAppointmentBundleAction = { type: ActionTypes.setAppointmentBundle; payload: AppointmentBundle }
type SetRecommendationAction = { type: ActionTypes.setRecommendations; payload: Recommendation }
type SetUpsellReferenceIdAction = { type: ActionTypes.setUpsellReferenceId; payload: Nullable<string> }
type SetSlotAction = { type: ActionTypes.setSlot; payload: Slot }
type SetBookingScreenTypeAction = { type: ActionTypes.setBookingScreenType; payload: BookingScreenType }
type SetTreatmentTitleAction = { type: ActionTypes.setTreatmentTitle; payload: string }
type SetPainDetails = { type: ActionTypes.setPainDetails; payload: PainDetailsData }
type SetMarketingParams = { type: ActionTypes.setMarketingParams; payload: MarketingParams }
type SetUpsellInformation = {
  type: ActionTypes.setUpsellInformation
  payload: AdditionalAppointmentHints['upsellInformation'] | undefined
}

type State = {
  clinicReferenceId: Nullable<string>
  recommendation: Nullable<Recommendation>
  upsellReferenceId: Nullable<string>
  practitionerReferenceId: Nullable<string>
  practitionerReferenceIds: Array<string>
  appointmentBundleReferenceId: Nullable<string>
  treatmentBundleReferenceId: Nullable<string>
  upsellTreatmentBundleReferenceId: Nullable<string>
  appointmentBundle: Nullable<AppointmentBundle>
  bookingScreenType: BookingScreenType
  treatmentTitle: Nullable<string>
  manipulatedAppointment: Nullable<ManipulatedAppointment>
  painDetails: Nullable<PainDetailsData>
  upsellInformation?: AdditionalAppointmentHints['upsellInformation']
  slot: Slot
  marketingParams: Nullable<MarketingParams>
}

type Action =
  | SetBookingsIdsAction
  | SetRescheduledAppointmentAction
  | SetDefaultBookingsContextAction
  | SetRecommendationDefaultBookingsContextAction
  | SetRecommendationAction
  | SetAppointmentBundleAction
  | SetSlotAction
  | SetBookingScreenTypeAction
  | SetTreatmentTitleAction
  | SetUpsellReferenceIdAction
  | SetPainDetails
  | SetMarketingParams
  | SetUpsellInformation

type Dispatch = (action: Action) => void

const BookingsContext = createContext<State | undefined>(undefined)

const BookingsDispatchContext = createContext<Dispatch | undefined>(undefined)

export const defaultBookingsState = {
  clinicReferenceId: null,
  practitionerReferenceId: null,
  practitionerReferenceIds: [],
  appointmentBundleReferenceId: null,
  treatmentBundleReferenceId: null,
  upsellTreatmentBundleReferenceId: null,
  appointmentBundle: null,
  bookingScreenType: BookingScreenType.Default,
  treatmentTitle: null,
  recommendation: null,
  upsellReferenceId: null,
  upsellInformation: undefined,
  manipulatedAppointment: null,
  painDetails: null,
  marketingParams: null,
  slot: {
    duration: 0,
    start: '',
  },
}

const bookingsReducer = (persist) => (state: State, action: Action) => {
  const finalize = (nextState: State) => {
    persist(nextState)
    return nextState
  }

  switch (action.type) {
    case ActionTypes.setDefaultBookingsContext:
      return finalize({ ...defaultBookingsState })

    case ActionTypes.setManipulatedAppointment:
      return finalize({ ...state, manipulatedAppointment: action.payload })

    case ActionTypes.setRecommendationDefaultBookingsContext:
      return finalize({ ...defaultBookingsState, recommendation: state.recommendation })

    case ActionTypes.setBookingIds:
      return finalize({ ...state, ...action.payload })

    case ActionTypes.setRecommendations:
      return finalize({ ...state, recommendation: action.payload })

    case ActionTypes.setUpsellReferenceId:
      return finalize({ ...state, upsellReferenceId: action.payload })

    case ActionTypes.setSlot:
      return finalize({ ...state, slot: { ...state.slot, ...action.payload } })

    case ActionTypes.setAppointmentBundle:
      return finalize({ ...state, appointmentBundle: action.payload })

    case ActionTypes.setBookingScreenType:
      return finalize({ ...state, bookingScreenType: action.payload })

    case ActionTypes.setTreatmentTitle:
      return finalize({ ...state, treatmentTitle: action.payload })

    case ActionTypes.setPainDetails:
      return finalize({ ...state, painDetails: action.payload })

    case ActionTypes.setMarketingParams:
      return finalize({ ...state, marketingParams: action.payload })

    case ActionTypes.setUpsellInformation:
      return finalize({ ...state, upsellInformation: action.payload })

    default:
      throw new Error(`Unhandled action`)
  }
}

export const BookingsProvider = ({ children }) => {
  const [value, setValue] = useLocalStorage(LocalStorage.Bookings, defaultBookingsState)

  const [state, dispatch] = useReducer(bookingsReducer(setValue), { ...value })

  return (
    <BookingsContext.Provider value={state}>
      <BookingsDispatchContext.Provider value={dispatch}>{children}</BookingsDispatchContext.Provider>
    </BookingsContext.Provider>
  )
}

export const useBookings = () => {
  const context = useContext(BookingsContext)

  if (context === undefined) {
    throw new Error('useBookings must be used within a BookingsProvider')
  }

  return context
}

export const useBookingsDispatch = () => {
  const context = useContext(BookingsDispatchContext)

  if (context === undefined) {
    throw new Error('useBookingsDispatch must be used within a BookingsProvider')
  }

  return context
}

export const setBookingIds = (payload: BookingIds): SetBookingsIdsAction => ({
  type: ActionTypes.setBookingIds,
  payload,
})

export const setManipulatedAppointment = (payload: ManipulatedAppointment): SetRescheduledAppointmentAction => ({
  type: ActionTypes.setManipulatedAppointment,
  payload,
})

export const setDefaultBookingContext = (): SetDefaultBookingsContextAction => ({
  type: ActionTypes.setDefaultBookingsContext,
})

export const setRecommendationDefaultBookingContext = (): SetRecommendationDefaultBookingsContextAction => ({
  type: ActionTypes.setRecommendationDefaultBookingsContext,
})

export const setAppointmentBundle = (payload: AppointmentBundle): SetAppointmentBundleAction => ({
  type: ActionTypes.setAppointmentBundle,
  payload,
})

export const setRecommendation = (payload: Recommendation): SetRecommendationAction => ({
  type: ActionTypes.setRecommendations,
  payload,
})

export const setUpsellReferenceId = (payload: Nullable<string>): SetUpsellReferenceIdAction => ({
  type: ActionTypes.setUpsellReferenceId,
  payload,
})

export const setSlot = (payload: Slot): SetSlotAction => ({
  type: ActionTypes.setSlot,
  payload,
})

export const setBookingScreenType = (payload: BookingScreenType): SetBookingScreenTypeAction => ({
  type: ActionTypes.setBookingScreenType,
  payload,
})

export const setTreatmentTitle = (payload: string): SetTreatmentTitleAction => ({
  type: ActionTypes.setTreatmentTitle,
  payload,
})

export const setPainDetails = (payload: Nullable<PainDetailsData>): SetPainDetails => ({
  type: ActionTypes.setPainDetails,
  payload,
})

export const setMarketingParams = (payload: Nullable<MarketingParams>): SetMarketingParams => ({
  type: ActionTypes.setMarketingParams,
  payload,
})

export const setVitaminDUpsellInformation = (): SetUpsellInformation => ({
  type: ActionTypes.setUpsellInformation,
  payload: vitaminDUpsellInformation,
})

export const clearVitaminDUpsellInformation = (): SetUpsellInformation => ({
  type: ActionTypes.setUpsellInformation,
  payload: undefined,
})
