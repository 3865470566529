import { useEffect } from 'react'

import OneSignal from 'react-onesignal'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

export const useOneSignal = () => {
  const personReferenceId = usePersonId()

  useEffect(() => {
    if (
      window['Cypress'] ||
      !personReferenceId ||
      !process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID ||
      process.env.NEXT_PUBLIC_ENV !== 'staging'
    )
      return

    try {
      OneSignal.init({
        appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID,
        notifyButton: {
          enable: true,
        },
      })

      OneSignal.login(personReferenceId)
    } catch (error) {
      console.warn(error)
    }
  }, [])
}
