import { Language } from 'src/@types/Language'

const COUNTRY_FLAGS_URL = process.env.NEXT_PUBLIC_COUNTRY_FLAGS_URL

export const getImage = (code: string) => `${COUNTRY_FLAGS_URL}${code}.svg`

// Language codes sometimes differ from country codes, so we need to map them in order to display correct flag
export const languageCodeToCountryCodeFallback = new Map<keyof typeof Language, string>([
  ['ja', 'jp'],
  ['da', 'dk'],
  ['zh', 'cn'],
  ['cs', 'cz'],
  ['en', 'gb'],
  ['ku', 'iq'],
  ['fa', 'ir'],
  ['el', 'gr'],
  ['sq', 'al'],
  ['hi', 'in'],
  ['ti', 'er'],
  ['ar', 'ps'],
  ['sr', 'rs'],
  ['vi', 'vn'],
  ['bs', 'ba'],
  ['sw', 'ke'],
])
