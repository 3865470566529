export enum LocalStorage {
  RedirectUrl = 'redirectUrl',
  Auth = 'auth',
  OnboardingDismissed = 'onboardingDismissed',
  BiometricLoginDismissed = 'biometricLoginDismissed',
  BiometricLoginActive = 'biometricLoginActive',
  TrustedDeviceId = 'trustedDeviceId',
  Authorized = 'authorized',
  Ana = 'ana',
  Bookings = 'bookings',
  Recommendations = 'recommendations',
  Unauthorized = 'unauthorized',
}
