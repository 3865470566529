import { dialogContentClasses, svgIconClasses } from '@mui/material'
import MuiDialog, { dialogClasses } from '@mui/material/Dialog'
import BaseDialogAction from '@mui/material/DialogActions'
import BaseDialogContent from '@mui/material/DialogContent'
import BaseDialogTitle, { dialogTitleClasses } from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'

export const Root = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== 'icon',
})<{ icon: boolean }>(({ theme, icon }) => ({
  [`& .${dialogClasses.paper}`]: {
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    maxWidth: 312,
    width: '100%',
  },

  [`& .${dialogContentClasses.root}`]: {
    position: 'relative',
  },

  [`& .${dialogTitleClasses.root}`]: {
    textAlign: icon ? 'center' : 'left',
    padding: theme.spacing(icon ? 2 : 3, 3, 2, 3),
  },
}))

export const DialogIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.grey[700],
  textAlign: 'center',

  [`.${svgIconClasses.root}`]: {
    margin: theme.spacing(3, 3, 0, 3),
  },
}))

export const DialogTitle = styled(BaseDialogTitle)(({ theme }) => ({
  color: theme.palette.grey[800],
}))

DialogTitle.defaultProps = { variant: 'Lato H4' }

export const DialogContent = styled(BaseDialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  color: theme.palette.grey[700],
  ...theme.typography['Lato Body 2'],
}))

export const DialogActions = styled(BaseDialogAction)(({ theme }) => ({
  padding: theme.spacing(3, 2, 3, 3),
}))
