import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const EmptyStar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" sx={{ width: 16, height: 16 }}>
      <path
        d="M8.34357 11.4913L7.99995 11.2839L7.65633 11.4913L4.34376 13.4906C4.33615 13.4952 4.33224 13.4961 4.33186 13.4962L4.33185 13.4962L4.33124 13.4963C4.33021 13.4962 4.32398 13.4952 4.31588 13.4894C4.30778 13.4835 4.30498 13.4779 4.30463 13.477L4.30456 13.4766L4.30456 13.4766C4.30453 13.4763 4.30422 13.4724 4.30621 13.4638L4.30622 13.4638L5.18556 9.69509L5.27677 9.30419L4.97338 9.04136L2.04605 6.50536L2.04584 6.50519C2.0392 6.49943 2.03715 6.49607 2.03699 6.4958L2.03698 6.49579L2.03681 6.49543C2.03658 6.49454 2.03556 6.48841 2.03864 6.47894C2.04173 6.46946 2.04616 6.4651 2.04686 6.46452C2.04695 6.46445 2.04684 6.46449 2.0472 6.46434L2.04722 6.46434C2.04751 6.46422 2.05114 6.4627 2.0599 6.46196L2.06013 6.46194L5.91346 6.13527L6.31308 6.1014L6.46957 5.73214L7.97675 2.17583C7.97675 2.17582 7.97676 2.17581 7.97676 2.17581C7.98026 2.16757 7.98289 2.16456 7.98309 2.16434L7.98356 2.16389C7.98444 2.16334 7.99001 2.16048 7.99995 2.16048C8.0099 2.16048 8.01546 2.16334 8.01635 2.16389L8.01682 2.16434L8.01683 2.16435C8.01708 2.16464 8.01969 2.16767 8.02314 2.17578C8.02314 2.1758 8.02315 2.17581 8.02315 2.17583L9.53034 5.73214L9.68683 6.1014L10.0864 6.13527L13.9398 6.46194L13.94 6.46196C13.9489 6.46271 13.9525 6.46427 13.9527 6.46434C13.9531 6.46449 13.953 6.46445 13.953 6.46452C13.9537 6.4651 13.9582 6.46946 13.9613 6.47893C13.9643 6.48841 13.9633 6.49454 13.9631 6.49543L13.9629 6.49579L13.9629 6.4958C13.9628 6.49607 13.9607 6.49944 13.9541 6.50519L13.9539 6.50536L11.0265 9.04136L10.7231 9.30419L10.8143 9.69509L11.6937 13.4638L11.6937 13.4638C11.6957 13.4724 11.6954 13.4763 11.6953 13.4766V13.4766L11.6953 13.477C11.6949 13.4779 11.6921 13.4835 11.684 13.4894C11.6759 13.4952 11.6697 13.4962 11.6687 13.4963L11.6681 13.4962L11.668 13.4962C11.6677 13.4961 11.6638 13.4952 11.6561 13.4906L8.34357 11.4913Z"
        stroke={colors.warning[300]}
        strokeWidth="1.33"
      />
    </SvgIcon>
  )
}

export default EmptyStar
