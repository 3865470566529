import React from 'react'

import { colors } from '@dentalux/ui-library-core/cjs/constants/colors/colors'

import { SvgIcon, SvgIconProps } from '@mui/material'

const Error = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 161 160" fill="none" sx={{ width: 161, height: 160, ...props.sx }}>
      <path
        d="M77.9572 53.9178C78.231 53.9179 78.5016 53.858 78.7499 53.7423C78.9981 53.6267 79.2181 53.4581 79.3942 53.2484C79.5704 53.0388 79.6986 52.7931 79.7697 52.5286C79.8408 52.2641 79.8532 51.9873 79.8059 51.7175L77.3669 37.8681C77.3242 37.6256 77.2341 37.3939 77.1019 37.1862C76.9696 36.9785 76.7977 36.7988 76.5961 36.6576C76.3944 36.5163 76.1668 36.4161 75.9264 36.3627C75.6861 36.3094 75.4375 36.3039 75.195 36.3466C74.9525 36.3893 74.7207 36.4793 74.513 36.6116C74.3053 36.7438 74.1257 36.9157 73.9844 37.1174C73.8431 37.3191 73.7429 37.5466 73.6896 37.787C73.6362 38.0274 73.6307 38.2759 73.6734 38.5185L76.1128 52.3678C76.1892 52.8017 76.4158 53.1948 76.7531 53.4783C77.0904 53.7617 77.5166 53.9173 77.9572 53.9178V53.9178Z"
        fill={colors.grey[800]}
      />
      <path
        d="M68.7285 55.544C69.0625 55.5438 69.3904 55.4543 69.6782 55.2848C69.966 55.1154 70.2033 54.8721 70.3656 54.5802C70.5279 54.2883 70.6093 53.9583 70.6014 53.6244C70.5934 53.2905 70.4964 52.9648 70.3204 52.6809L65.8873 45.529C65.7575 45.3198 65.5879 45.1381 65.3879 44.9943C65.188 44.8506 64.9617 44.7477 64.722 44.6914C64.4823 44.6352 64.2338 44.6267 63.9908 44.6664C63.7478 44.7062 63.515 44.7934 63.3057 44.9231C63.0964 45.0528 62.9147 45.2225 62.771 45.4225C62.6273 45.6224 62.5243 45.8487 62.4681 46.0884C62.4118 46.3281 62.4033 46.5766 62.4431 46.8196C62.4828 47.0626 62.57 47.2954 62.6998 47.5047L67.1332 54.6562C67.3014 54.9279 67.5363 55.152 67.8155 55.3074C68.0947 55.4628 68.409 55.5443 68.7285 55.544V55.544Z"
        fill={colors.grey[800]}
      />
      <path
        d="M87.0793 41.7966L85.3568 50.0313C85.2551 50.518 85.3508 51.0253 85.623 51.4414C85.8953 51.8576 86.3217 52.1486 86.8084 52.2503C87.2952 52.3521 87.8024 52.2564 88.2186 51.9841C88.6347 51.7119 88.9257 51.2855 89.0275 50.7988L90.75 42.5628C90.8517 42.0761 90.756 41.5688 90.4838 41.1527C90.2115 40.7365 89.7852 40.4455 89.2984 40.3438C89.0574 40.2934 88.8088 40.2909 88.5669 40.3366C88.3249 40.3823 88.0943 40.4752 87.8882 40.61C87.4721 40.8822 87.1811 41.3086 87.0793 41.7953V41.7966Z"
        fill={colors.grey[800]}
      />
      <path
        d="M36.4402 122.574C36.3962 122.602 36.3512 122.628 36.3046 122.652C36.2261 122.689 36.1598 122.748 36.1136 122.821C36.0673 122.894 36.0429 122.979 36.0433 123.066C36.0437 123.153 36.0689 123.238 36.116 123.311C36.163 123.383 36.2298 123.441 36.3087 123.478C36.3944 123.534 36.4838 123.585 36.5762 123.63C43.3959 124.264 51.8174 118.979 57.4446 113.802C57.8546 113.425 58.2337 113.071 58.5874 112.739C63.7009 107.963 65.0037 107.527 73.633 111.333C78.1293 113.316 85.5777 109.687 88.9602 103.864C90.9252 100.482 92.9387 93.8575 84.3437 87.495C81.8612 85.6575 80.1702 85.0437 78.2127 84.3328C77.5905 84.1069 76.9334 83.8681 76.2224 83.5791C76.9968 85.6756 77.9809 87.2041 79.228 88.2778C79.6051 88.6022 79.8379 89.0631 79.8752 89.5592C79.9125 90.0552 79.7512 90.5457 79.4268 90.9228C79.1024 91.2999 78.6415 91.5327 78.1455 91.57C77.6494 91.6073 77.1589 91.446 76.7818 91.1216C73.4184 88.2269 72.1546 83.705 71.4965 80.5556C71.3599 79.9022 71.2302 79.0041 71.0793 77.9644C70.5955 74.6206 69.864 69.5653 68.0949 66.5844C66.3137 63.5812 61.2199 56.9509 52.4387 60.2359C46.5902 62.4234 42.354 68.1322 43.1887 72.7C44.8818 81.9759 44.154 83.1409 38.3109 86.9984C37.9046 87.2666 37.4706 87.5534 37.0056 87.8653C30.6562 92.1272 23.549 99.0816 22.5706 105.859C22.5925 105.959 22.6212 106.058 22.6562 106.154C22.6727 106.239 22.7134 106.318 22.7732 106.381C22.8331 106.444 22.9097 106.488 22.994 106.509C23.0783 106.53 23.1668 106.526 23.249 106.498C23.3313 106.47 23.4038 106.419 23.4581 106.352C23.4918 106.313 23.5273 106.275 23.5646 106.239C24.1074 105.71 37.0059 93.2916 49.6106 93.5434C50.4357 93.5658 51.2457 93.7706 51.9824 94.143C52.7191 94.5154 53.3643 95.0462 53.8716 95.6974C54.3789 96.3486 54.7358 97.104 54.9166 97.9095C55.0975 98.7149 55.0979 99.5504 54.9177 100.356C52.0865 112.573 37.0777 122.172 36.4402 122.574V122.574Z"
        fill={colors.grey[800]}
      />
      <path
        d="M142.928 81.0938C147.268 78.7897 149.028 70.6931 146.549 64.4331C145.108 60.7963 141.152 55.1147 130.835 57.9269C127.855 58.7394 126.326 59.6872 124.556 60.7847C123.993 61.1334 123.4 61.5034 122.733 61.8813C124.858 62.5709 126.67 62.7447 128.283 62.4294C128.769 62.3391 129.271 62.4444 129.68 62.7223C130.089 63.0003 130.371 63.4284 130.466 63.9135C130.561 64.3986 130.46 64.9015 130.186 65.3128C129.912 65.7241 129.486 66.0105 129.002 66.1097C124.647 66.9603 120.313 65.1534 117.432 63.7209C116.834 63.4238 116.049 62.9709 115.139 62.4447C112.213 60.7547 107.79 58.2003 104.353 57.7447C97.0366 56.7741 91.686 59.8241 89.676 66.1122C87.7747 72.06 89.6354 78.9203 93.7385 81.0928C102.072 85.5044 102.536 86.7975 101.95 93.7741C101.909 94.2588 101.865 94.7778 101.822 95.3366C101.239 102.962 102.302 112.847 107.02 117.81C107.113 117.855 107.208 117.893 107.305 117.926C107.416 117.971 107.54 117.974 107.654 117.935C107.768 117.896 107.864 117.818 107.925 117.714C107.966 117.621 107.977 117.517 107.957 117.417C107.947 117.367 107.94 117.316 107.934 117.265C107.639 113.819 107.645 110.354 107.95 106.909C108.627 99.1241 110.69 93.0819 114.085 88.95C114.613 88.3156 115.276 87.8059 116.024 87.4577C116.772 87.1094 117.589 86.9312 118.414 86.9358C119.24 86.9404 120.054 87.1277 120.798 87.4843C121.543 87.8408 122.199 88.3578 122.72 88.9981C126.048 93.1494 128.068 99.1884 128.726 106.948C129.023 110.38 129.026 113.831 128.734 117.263C128.728 117.315 128.721 117.366 128.711 117.417C128.691 117.502 128.696 117.59 128.725 117.672C128.754 117.754 128.806 117.826 128.874 117.88C128.942 117.933 129.025 117.966 129.111 117.974C129.198 117.982 129.285 117.966 129.362 117.926C129.46 117.894 129.555 117.855 129.648 117.81C134.366 112.848 135.429 102.961 134.845 95.3366C134.803 94.7809 134.759 94.2644 134.72 93.7819C134.133 86.8078 134.597 85.5144 142.928 81.0938V81.0938Z"
        fill={colors.grey[800]}
      />
      <path
        d="M155.693 98.6909C150.695 96.5559 144.434 94.6497 137.082 93.025L136.273 96.6875C143.398 98.2625 149.436 100.097 154.22 102.141C154.676 102.33 155.189 102.331 155.646 102.145C156.103 101.958 156.468 101.599 156.662 101.145C156.856 100.691 156.863 100.179 156.682 99.7193C156.5 99.2601 156.145 98.891 155.693 98.6922V98.6909Z"
        fill={colors.grey[800]}
      />
      <path
        d="M26.2188 92.6722C17.9267 94.3547 10.8973 96.3769 5.32635 98.6825C5.09883 98.7767 4.89209 98.9147 4.71794 99.0888C4.54379 99.2629 4.40562 99.4696 4.31134 99.6971C4.21706 99.9245 4.16851 100.168 4.16846 100.415C4.1684 100.661 4.21685 100.905 4.31103 101.132C4.40522 101.36 4.54329 101.566 4.71737 101.741C4.89145 101.915 5.09813 102.053 5.3256 102.147C5.55307 102.241 5.79689 102.29 6.04313 102.29C6.28937 102.29 6.53321 102.242 6.76072 102.148C12.1098 99.9334 18.9073 97.9822 26.9648 96.3472L26.2188 92.6722Z"
        fill={colors.grey[800]}
      />
      <path d="M90.6953 89.6506H100.24V93.4006H90.6953V89.6506Z" fill={colors.grey[800]} />
    </SvgIcon>
  )
}

export default Error
