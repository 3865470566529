import { Components, Theme } from '@mui/material'

const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[0],
    }),
    indicator: ({ theme }) => ({
      display: 'flex',
      transition: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',

      span: {
        width: '100%',
        maxWidth: theme.spacing(2.5),
        borderRadius: theme.spacing(12.5, 12.5, 0, 0),
        backgroundColor: theme.palette.secondary[800],
        height: 3,
      },
    }),
  },
  defaultProps: {
    visibleScrollbar: false,
    variant: 'scrollable',
  },
}

export default MuiTabs
