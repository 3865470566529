import { useMutation } from 'react-query'

import { usePersonId } from 'src/components/providers/PersonIdProvider'

import { api } from '../../helpers'

export const useAnamnesisSourceMutation = () => {
  const personReferenceId = usePersonId()

  return useMutation(
    ({ anamnesisReferenceId }: { anamnesisReferenceId: string }) =>
      api.ana.getAnamnesisById({ personReferenceId, anamnesisReferenceId }),
    { useErrorBoundary: false }
  )
}
