import { gql } from '@apollo/client'

export const ClinicsFragments = {
  Clinic: gql`
    fragment Clinic on Clinic {
      referenceId
      city
      houseNumber
      lat
      long
      email
      officialName
      phoneNumber
      street
      webSiteUrl
      zipCode
    }
  `,
}

export const CLINICS_QUERY = gql`
  query CLINICS($personReferenceId: UUID!, $page: Int, $size: Int) {
    clinics(id: $personReferenceId, page: $page, size: $size) {
      data {
        clinic {
          ...Clinic
        }
        lastVisitedDateTime
        isClinicActive
      }
    }
  }
  ${ClinicsFragments.Clinic}
`
