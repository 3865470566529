import { Components, Theme } from '@mui/material'

const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    list: ({ theme }) => ({
      padding: theme.spacing(1, 0),
    }),
  },
}

export default MuiMenu
